import Storage from "../../utils/storega";
import { TYPES } from "../actions";
import {
  clearPrResolve
} from "../../utils/request";

const initialState = {
  status: null,
  error: null,
  token: null,
  examId: null,
  listUsers: [],
  isListEmpty: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    // ACTIONS WITHOUT DATA WILL BE PLACES HERE
    case TYPES.SIGN_IN_REQUEST:
    case TYPES.GET_PROFILE_REQUEST:
      return {
        ...state,
        status: action.type
      };

    // ACTIONS WITH DATA WILL BE PLACES HERE
    case TYPES.SIGN_IN_SUCCESS:
      Storage.set("USER_ACCESS_TOKEN", action.data.user.token);
      Storage.set("USER_PROFILE", action.data.user.profile);
      Storage.set("USER_TODAY_LOGIN", action.data.user.todayLogin);
      Storage.set("USER_LOGIN_SANARU", action.data.user.isCheckedSanaru);
      Storage.set("USER_STUDY_MODE", action.data.user);

      return {
        ...state,
        status: action.type,
        token: action.data.user.token,
        examId: action.data.examId
      };

    case TYPES.GET_PROFILE_SUCCESS:
      Storage.set("USER_PROFILE", action.data.profile);
      return {
        ...state,
        status: action.type
      };

    case TYPES.CLEAR_DATA_AUTH_SUCCESS:
      return {
        ...initialState
      }

    case TYPES.LOGOUT:
      clearPrResolve();
      Storage.remove("USER_ACCESS_TOKEN");
      Storage.remove("USER_PROFILE");
     // Storage.remove("SCHOOL_ID");
      Storage.remove("CURRENT_EXAM_BY_USER");
      Storage.remove("CURRENT_INFO");
     
      return false;

    // ACTION FAILED WILL BE PLACED HERE
    case TYPES.SIGN_IN_FAILED:
    case TYPES.GET_PROFILE_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error
      };
    case 'GET_LIST_USER_SUCCESS':
      return {
        ...state,
        status: action.type,
        listUsers: action.data.listUsers,
        isListEmpty: action.data.listUsers.length === 0
      }
    case 'UPDATE_LIST_USER_SUCCESS': 
        // eslint-disable-next-line no-case-declarations
        const updatedListUsers = state.listUsers.map((user) =>
          user.username === action.data?.id ? { ...user, ...action.data?.data } : user
        );
      
        return {
          ...state,
          // status: action.type,
          listUsers: updatedListUsers
        };
      
    default:
      return state;
  }
}