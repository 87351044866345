import { combineReducers } from "redux";
import storage from 'redux-persist/lib/storage';
import auth from './auth';
import rank from './rank';
import roadMap from "./roadMap";
import schedule from './schedule';
import common from './common'

const appReducer = combineReducers({
  auth,
  schedule,
  roadMap,
  rank,
  common
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    storage.removeItem('persist:root');
    return appReducer(undefined, action)
  }

  return appReducer(state, action);
};

export default rootReducer;