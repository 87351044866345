import React, { createContext, useMemo } from "react";
import styled, { css } from "styled-components";
import { useHistory } from "react-router-dom";
import background from "./assets/background.png";
import slogan from "./assets/newLogoSlogan.png";
// import backgroundClip from "./assets/demo1.mp4";
import { useLongPress } from "./hooks/useLongPress";
import { PopupNew } from "./components/Popup";

const HomePageContext = createContext();

const breakpoint = {
  tabletMin: 768,
  tabletMax: 1024,
  laptopMin: 1025,
  laptopMax: 1440,
  desktopMin: 1441,
  desktopMax: 2560
};
const media = Object.keys(breakpoint).reduce((style, device) => {
  style[device] = (...args) => css`
    @media (max-width: ${breakpoint[device]}px) {
      ${css(...args)}
    }
  `;
  return style;
}, {});
const mediaRotation = Object.keys(breakpoint).reduce((style, device) => {
  style[device] = (...args) => css`
    @media (max-width: ${breakpoint[device]}px) and (orientation: landscape) {
      ${css(...args)}
    }
  `;
  return style;
}, {});

const commonProprties = {
  background: css`
    background-image: url(${background});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  `,
  fontSegoe: css`
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-style: normal;
  `
};

const StyledHome = {
  Background: styled.div`
   position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

  `,
  Video: styled.video`
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  `,
  Slogan: styled.img`
  /* width: 623px; */
  /* height: 31.55px; */
  position: absolute;
  bottom: 0;
  left: 15px;
  /* width: calc(100% - 70vw);
  ${media.tabletMax`
    width: calc(100% - 50vw);
  `} */
  width: 140px;
  `,
  Layout: styled.div`
    /* ${commonProprties.background} */
    height: 100%;
    position: relative;
    .signup{
      display: flex;
    gap: 40px;
    align-items: center;
    justify-content: space-around;
    }
    ${({ centerScreen = false, flexColumn = false }) =>
      centerScreen &&
      css`
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        ${flexColumn ? "flex-direction: column;" : ""}
      `}
  `
};

const HomePageLayout = ({ children, styleLayout }) => {
  const channel = new BroadcastChannel('channel_error');
  const history = useHistory();
  channel.onmessage = (event) => {
   if(event.data === 'Maintain-503' || event.data === 'Unauthorized-400'){
    const clear = () => {
      window.localStorage.clear();
      window.sessionStorage.clear();
      // window.caches.keys().then((names) => {
      //   // eslint-disable-next-line no-restricted-syntax, prefer-const
      //   for (let name of names) window.caches.delete(name);
      // });
    }
       // clear broacast channel
       channel.close();
        if (localStorage?.getItem("MAIN_MODE")) {
          if(localStorage?.getItem("USER_ACCESS_TOKEN")){
            clear()
            setTimeout(() => {
              history.replace('/sign-in')
            }, 1000);
        } else {
          clear()
        }
        }
        
        
     
   }
  };

  //  const videoSource = backgroundClip;
   const videoRef = React.useRef(null);
   const [videoConfig, setVideoConfig] = React.useState({
      isPlaying: true,
      isMuted: true,
      isLoop: true,
      isAutoPlay: true
    });
    const [open, setOpen] = React.useState(false);

   const longPressEvent = useLongPress(() => setOpen(true), 3000);
   const closePopup = () => setOpen(false);
    // React.useEffect(() => {
    //   if (videoRef.current) {
    //     videoRef.current.play();
    //   }
    // }, [videoRef]);
    // change speed video to 0.5
    // React.useEffect(() => {
    //   if (videoRef?.current) {
    //     videoRef.current.playbackRate = 0.35;
    //     videoRef.current.defaultPlaybackRate = 0.35;

    //   }
    // }, [videoRef]);
    const handleVideoLoad = () => {
      if (videoRef.current) {
        videoRef.current.autoPlay = true;
      }
    };
  //  const UIBackground = (
  //     <StyledHome.Background>
  //       <StyledHome.Video
  //         ref={videoRef}
  //         // onLoadedData={handleVideoLoad}
  //         autoPlay
  //         muted
  //         loop
  //         preload="auto"
  //         playsInline
  //       >
  //         <source src={videoSource} type="video/mp4" />
  //       </StyledHome.Video>
  //     </StyledHome.Background>
  //   );
    const handleClickBg = () => {
      // if (videoRef.current) {
      //   if (videoRef.current.paused) {
      //     videoRef.current.play();
      //   } else {
      //     videoRef.current.pause();
      //   }
      // }
    }

  return (
    <HomePageContext.Provider value={{}}>
      <StyledHome.Layout {...styleLayout}>
        {children}
        <StyledHome.Slogan onDoubleClick={handleClickBg} src={slogan} alt="" {...longPressEvent} />
      </StyledHome.Layout>
      {/* {UIBackground} */}
      {open && <PopupNew title="AI学トレアプリを閉じる" content="AI学トレアプリを閉じますか？" submit={() => {
        // window.location.href = 'closekiosk'
        window.close()
      }} cancel={closePopup}/>}
    </HomePageContext.Provider>
  );
};
export {
  HomePageContext,
  HomePageLayout,
  commonProprties,
  media,
  mediaRotation,
  StyledHome
};
