import React, { Component } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './style.css';

toast.configure();
// eslint-disable-next-line consistent-return
const pushNotify = (type, message, id) => {
  if(!toast.isActive(id)) {
    toast.dismiss();
    switch (type) {
      case 'success':
        return toast.success(message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
          hideProgressBar: true,
          toastId: id
        });
      case 'error':
        return toast.error(message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
          hideProgressBar: true,
          toastId: 'refresh'
        });
      case 'warn':
        return toast.warn(message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
          hideProgressBar: true,
          toastId: id
        });
      case 'info':
        return toast.info(message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
          hideProgressBar: true,
          toastId: id
        });
      default:
        return toast(message);
    }
  }
};

// eslint-disable-next-line react/prefer-stateless-function
class Notification extends Component {
  render() {
    return (
      <ToastContainer 
        hideProgressBar
        autoClose={3000}
        closeOnClick
      />
    );
  }
}

export default Notification;
export { pushNotify };
