/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-props-no-spreading */

import React, { lazy, PureComponent, Suspense } from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import ImageContent from "../assets/images/exma.png";
import Loading from "../components/loading/loading";
import { SystemCode } from "../constant/commonData";
import RouterPath from "../constant/router-path";
import { HomePageLayout } from "../pages/CRs_T12_2022_HomePage";
import { logout, signIn } from "../store/actions/auth";
import { clearPrResolve } from "../utils/request";
import Storage from "../utils/storega";
import PrivateRoute from './privateRoute';
import { Container } from './style';
import backout from '../pages/CRs_T12_2022_HomePage/assets/backout.png';
import SignIn from "../pages/sign-in/signIn";
import { LoginAdmin } from "../pages/CRs_T12_2022_HomePage/pages/LoginAdmin";

const Ranking = lazy(() => import("../pages/ranking"))
const TreeDiv = lazy(() => import("../pages/map-demo-div"));
const StudyMode = lazy(() => import("../pages/study-mode"));
// const SignIn = lazy(() => import("../pages/sign-in"));
// const Schedule = lazy(() => import("../pages/schedule"));
const ScheduleDetails = lazy(() => import("../pages/schedule-details"));
// const HeaderSearch = lazy(() => import('../components/headerSearch'));
// const Middle = lazy(() => import('../pages/map-demo-div/middle'));

const LoadingPage = () => <Loading />;


class Routes extends PureComponent {
	async componentDidMount() {
		const getSearch = new URLSearchParams(this.props.location.search);
		const userId = await getSearch.get('userId');
		const examId = await getSearch.get('examId');

		//* 27-12 
		const autoCloseBrowser = getSearch.get('autoCloseBrowser');
		if (autoCloseBrowser) {
			Storage.set("Close_Browser", autoCloseBrowser);
		}
		//* 

		if (userId && examId) {
			//* 9-12 : tu scoring qua luc nay chua co main mode thi set mac dinh la input 
			if (Storage.get("MAIN_MODE") === null) {
				Storage.set("MAIN_MODE", 'input');
				Storage.set('OLD_MAIN_MODE', 'input');
			}
			const schoolId = Storage.get('SCHOOL_ID') === null || undefined ? getSearch.get('schoolId') : Storage.get('SCHOOL_ID');
			this.props.signIn({ schoolId, userId, examId })
		}
	}

	componentDidUpdate() {
		window.onpopstate = () => {
			clearPrResolve();
		}
	}

	_authCondition = () => Storage.has("USER_ACCESS_TOKEN");

	_noAuthCondition = () => !Storage.has("USER_ACCESS_TOKEN");

	/* eslint-disable-next-line react/display-name */
	_renderLazyComponent = (LazyComponent, params) => (props) => (
		<LazyComponent {...props} {...params} />
	)

	_renderAuthRoutes = () => (

			<Suspense fallback={false}>
					{/* <Suspense fallback={false}>  */}
					<Switch>
						{/* {[RouterPath.HOME.path, RouterPath.SCHEDULE.path].map(path => (
							<Route
								key={path}
								exact
								path={path}
								component={
									this._renderLazyComponent(Middle)
									// Storage.get('SYSTEM_CODE') !== SystemCode ? 
									// this._renderLazyComponent(Schedule) : 
									// this._renderLazyComponent(Middle)
								}
							/>
						))} */}
						{/* {[RouterPath.SCORING_MAP.path,
						RouterPath.SCORING_MAP_EXAM.path,
						RouterPath.SCORING_MAP_EXAM_EDIT.path].map(path => (
							<Route
								key={path}
								exact
								path={path}
								component={this._renderLazyComponent(TreeDiv)}
							/>
						))} */}
						<Route 
						  path={RouterPath.SCORING_MAP_NEW.path}
						  exact 
						  component={this._renderLazyComponent(TreeDiv)}
						/>
						<Route 
						  path={RouterPath.STUDY_MODE.path}
						  exact 
						  component={this._renderLazyComponent(StudyMode)}
						/>

						{/* <Route
							path={RouterPath.SCHEDULE_DETIALS.path}
							component={this._renderLazyComponent(ScheduleDetails)}
						/>
						<Route
							path={RouterPath.RANKING.path}
							component={this._renderLazyComponent(Ranking)}
						/> */}
						<Redirect to="/scoring" />
					</Switch>
				</Suspense>
	)

	_checkUrl = () => {
		const { location } = this.props;
		const { pathname } = location;
		const pathUrl = {
			// '/demo_layout': '/demo_layout',
			'/sign-in': '/sign-in',
			// '/demo_scoring': '/demo_scoring',
			// '/demo_print': '/demo_print'
            '/sign-in-admin': '/sign-in-admin'
		}
		return pathUrl[pathname] || '/sign-in';
	}

	render() {
		return (
			<HomePageLayout>
				<Suspense fallback={false}>
				<Switch>
					<>
						{/* <PrivateRoute
							path="/sign-in"
							component={this._renderLazyComponent(SignIn)}
							condition={this._noAuthCondition}
							redirect='/'
						/> */}
						<Route
							path="/sign-in-admin"
							component={this._renderLazyComponent(LoginAdmin)}
						/>
						<Route
							path="/sign-in"
							component={this._renderLazyComponent(SignIn)}
						/>
					
						<PrivateRoute
							path="/"
							condition={this._authCondition}
							component={this._renderAuthRoutes}
							redirect={this._checkUrl()}
						/>
					</>
				</Switch>
			</Suspense>
			</HomePageLayout>
		)
	}
}

const mapStateToProps = (state) => ({
	status: state.auth.status
});

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			signIn
		},
		dispatch
	);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Routes));