import React, { useContext } from "react";
import styled from "styled-components";
import { commonProprties, HomePageContext, media, mediaRotation } from "../index";
import logo from "../assets/logo.png";

const StyledLogo = {
  Logo: styled.div`
    text-align: -webkit-center;
  `,
  LogoText: styled.div`
    ${commonProprties.fontSegoe};
    color: #ffffff;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    margin-top: 21px;
  `,
  LogoImg: styled.div`
    background-image: url(${logo});
    background-size: contain;
    background-repeat: no-repeat;
    width: 450px;
    height: 450px;
    ${media.desktopMin`
      width: 300px;
      height: 300px;
    `};
    /* ${mediaRotation.tabletMax`
      width: 300px;
      height: 300px;
    `}; */
  `
};
export const Logo = () => {
  const Context = useContext(HomePageContext);
  return (
    <StyledLogo.Logo>
      <StyledLogo.LogoImg />
      <StyledLogo.LogoText>
        Artificial Intelligence Ability Training
      </StyledLogo.LogoText>
    </StyledLogo.Logo>
  );
};
