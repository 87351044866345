import styled from "styled-components";
import { BackgroundDark } from '../../components/contentBackground/style';
import { HomeBtn } from "../../components/headerSearch/style";

export const SmallBgDark = styled(BackgroundDark)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 84px;
  padding: .5rem 1.125rem;
`
export const CellInfo = styled.div`
  flex: 0 0 ${({ sideWidth }) => sideWidth || '284'}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-right: 1px solid ${({ theme }) => theme.primaryColor};
  color: ${({ theme }) => theme.textDefault};
  font-size: 14px;
  overflow: hidden;
  
  &:last-child {
    border-right: none;
  }

  label {
    color: ${({ theme }) => theme.borderColor};
    margin-bottom: 0;
    font-weight: 300;
  }

  span {
    width: 90%;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 15px;
    overflow: hidden;
  }
`

export const BtnDetail = styled(HomeBtn)`
  background-color: ${({ theme, status }) => theme.buttonColor[status]};
  background: ${({ theme, status }) => theme.buttonColor[status]};
  border: 1px solid transparent;
  transition: all 0.5s ease-out;
  min-width: 53px;
  padding: 4px 8px;
  color: ${({ theme }) => theme.textDefault};
  white-space: nowrap;
  font-weight: 400;
  :hover,
  :focus,
  :active {
    outline: none !important;
    box-shadow: none !important;
    border: 1px solid ${({ theme }) => theme.textDefault} !important;
    background-color:${({ theme, status }) => theme.buttonColor[status]} !important;
  }

  &.disabled {
    pointer-events: none;
    background-color:${({ theme, status }) => theme.buttonColor[status]} !important;
  }
`
