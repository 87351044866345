import React from 'react';
import styled from 'styled-components';
import { StyledHome } from '../index';
import backgroundClip from "../assets/demo1.mp4";

export const BackgroundVideo = () => {
    const videoRef = React.useRef(null);
    React.useEffect(() => {
        if (videoRef?.current) {
          videoRef.current.playbackRate = 0.35;
          videoRef.current.defaultPlaybackRate = 0.35;
  
        }
      }, [videoRef]);

    const UI = (
        <StyledHome.Background>
        <StyledHome.Video
          ref={videoRef}
          autoPlay
          muted
          loop
          preload="auto"
          playsInline
        >
          <source src={backgroundClip} type="video/mp4" />
        </StyledHome.Video>
      </StyledHome.Background>
    )
    return UI;
}