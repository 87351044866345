

export const messageError = {
    "INCORRECT_SCHOOLID_OR_USERID": "ログインIDが違います。",
    "TIME_INVALID" : "TIME_INVALID",
    "LESSON_COURSE_NOT_FOUND":"LESSON_COURSE_NOT_FOUND",
    "STEP_NOT_FOUND":"STEP_NOT_FOUND",
    "QUESTION_NOT_FOUND":"該当のデータが見つかりません。",
    "LESSON_NOT_FOUND":"LESSON_NOT_FOUND",
    "LESSON_STATUS_NOT_FOUND":"LESSON_STATUS_NOT_FOUND",
    "EXAM_NOT_FOUND":"該当のデータが見つかりません。",
    "EXAM_INFO_NOT_FOUND":"該当のデータが見つかりません。",
    "EXAM_CONFIRMATION_A_NOT_FOUND":"確認\nテストＡ",
    "QUESTIONS_HAVE_BEEN_DELETED":"該当のデータが見つかりません。",
    "EXAM_HAS_BEEN_SCORED":"結果はすでに読み取りました。",
    "SUBJECT_NOT_FOUND":"SUBJECT_NOT_FOUND",
    "USER_LESSON_COURSE_NOT_FOUND":"USER_LESSON_COURSE_NOT_FOUND",
    "FILE_NOT_FOUND":"FILE_NOT_FOUND",
    "LARGE_UNIT_NOT_FOUND":"LARGE_UNIT_NOT_FOUND",
    "USER_NOT_FOUND":"USER_NOT_FOUND",
    "EXAM_ID_IS_INVALID":"テストIDが認識できません。",
    "SCHEDULE_NOT_FOUND":"SCHEDULE_NOT_FOUND",
    "PREVIOUS_STEP_NOT_FOUND":"PREVIOUS_STEP_NOT_FOUND",
    "CAN_NOT_CREATE_RETEST_CONFIRMATION":"CAN_NOT_CREATE_RETEST_CONFIRMATION",
    "CANNOT_CREATE_EXAM":"PDFファイルが生成できません。",
    "EXAM_SCORE_IS_REQUIRED":"不正な点数が入力されました。",
    "TOTAL_SCORE_IS_REQUIRED":"不正な点数が入力されました。",
    "EXAM_SCORE_HAVE_TO_LESS_OR_EQUAL_THAN_TOTAL_SCORE":"不正な点数が入力されました。",
    "TOTAL_SCORE_HAVE_TO_LESS_OR_EQUAL_THAN_ZERO":"不正な点数が入力されました。",
    "Some input score invalid":"不正な点数が入力されました。",
    "SYSTEM_IS_MAINTAINING" : "システムメンテナンス中です。\r\nしばらくお待ち下さい。",
    "DEBUG_SHOW_ERROR": "Show error success",
    "default" : "サーバーエラー",
    "LESSON NOT FOUND" : "該当のデータが見つかりません。",
    "ERROR_REQUEST_TIMEOUT": "エラーが発生しました。もう一度試してください。",
    "CAN_NOT_CREATE_EXAM_DUE_TO_EXIST_OTHER_EXAM": "別の試験を生成する前に採点を完了してください。",
    "MOSHI_ID_IS_INCONSISTENT": "同模試から大問を選択してください。"
  }
export const MESSAGE_ERROR_WEB = {
  "CANNOT_GENERATE_EXAM": "プリントアウト出来ませんでした。もう一度試してください。"
}