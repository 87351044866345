import React, { useMemo } from "react";
import Select from "react-select";

export const SelectSchoolBuilding = ({ data, schoolBuildingId, setSchoolBuildingId, callApi }) => {
  const options = useMemo(
    () =>
      data?.map((item) => ({
        value: item?.schoolBuildingId,
        label: item?.schoolBuildingName
      })),
    [data]
  );
  return (
    <Select
      id="periodCode"
      name="periodCode"
      onChange={(event) => {
        setSchoolBuildingId(event.value);
        callApi();
      }}
      value={options?.find((item) => item.value === schoolBuildingId)}
      style={{ WebkitAppearance: "auto", marginBottom: "5px" }}
      options={options}
      placeholder="選択..."
      noOptionsMessage={() => "該当データなし"}
    />
  );
};
