/* eslint-disable import/no-anonymous-default-export */

const initialState = {
    loading: false
  };
  
  export default (state = initialState, action) => {
    switch (action.type) {
      case 'LOADING_ON':
        return {
          ...state,
          loading: true
        };
      case 'LOADING_OFF':
        return {
          ...state,
          loading: false
        };
      default:
        return state;
    }
  };
  