import { TYPES } from "../actions";

const initialState = {
  status: null,
  error: null,
  scheduleData: null,
  pageNumber: 0,
  pageSize: 0,
  totalElements: 0,
  totalPages: 1,
  isLoading: false,
  scheduleDetialsData: {},
  subjectListData: [],
  codeSelect: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    // ACTIONS WITHOUT DATA WILL BE PLACES HERE
    case TYPES.GET_SCHEDULE_REQUEST:
    case TYPES.GET_SCHEDULE_DETAILS_REQUEST:
    case TYPES.GET_SUBJECT_LIST_REQUEST:
      return {
        ...state,
        status: action.type,
        isLoading: true
      }

    // ACTIONS WITH DATA WILL BE PLACES HERE
    case TYPES.GET_SCHEDULE_SUCCESS:
      return {
        ...state,
        status: action.type,
        scheduleData: action.data.schedule.content,
        pageNumber: action.data.schedule.pageNumber,
        pageSize: action.data.schedule.pageSize,
        totalElements: action.data.schedule.totalElements,
        totalPages: action.data.schedule.totalPages,
        codeSelect: action.data.codeSelect || null,
        isLoading: false
      }

    case TYPES.GET_SCHEDULE_DETAILS_SUCCESS:
      return {
        ...state,
        status: action.type,
        scheduleDetialsData: action.data.scheduleDetails,
        pageNumber: action.data.scheduleDetails?.smallUnitLessons?.pageNumber,
        pageSize: action.data.scheduleDetails?.smallUnitLessons?.pageSize,
        totalElements: action.data.scheduleDetails?.smallUnitLessons?.totalElements,
        totalPages: action.data.scheduleDetails?.smallUnitLessons?.totalPages,
        isLoading: false
      }

    case TYPES.GET_SUBJECT_LIST_SUCCESS:
      
      return {
        ...state,
        status: action.type,
        subjectListData: action.data.subjectList.content,
        isLoading: false
      }

    case TYPES.CLEAR_DATA_SCHEDULE_DETAILS_SUCCESS:
      return {
        ...state,
        status: action.type,
        scheduleDetialsData: {},
        isLoading: false
      }

    case TYPES.RESTART_SCHEDULE_DETAILS:
      return {
        ...state,
        scheduleData: null
      }
    
    case TYPES.SET_CODE_SELECT:
      // console.log(state);
      return {
        ...state,
        codeSelect : action.data.codeSelect
      }
    // ACTION FAILED WILL BE PLACED HERE
    case TYPES.GET_SCHEDULE_FAILED:
    case TYPES.GET_SCHEDULE_DETAILS_FAILED:
    case TYPES.GET_SUBJECT_LIST_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.err?.messageId,
        isLoading: false
      };
    default:
      return state;
  }
}