import React from 'react';
import styled from 'styled-components';

const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 15px;
  right: 15px;
`;

const SwitchInput = styled.input`
  display: none;
`;

const SwitchLabel = styled.label`
  position: relative;
  cursor: pointer;
  width: 40px;
  height: 25px;
  background-color: ${({ isOff }) => !isOff ? '#fff' : '#4CAF50'};
  border-radius: 34px;
  transition: background-color 0.2s;

`;

const SwitchKnob = styled.span`
  position: absolute;
  top: 3px;
  left: 2px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0,0,0,0.4);
  transition: transform 0.2s;
    ${({ isOff }) => isOff && `transform: translateX(17px);`};
  
`;

const Switch = ({ checked, onChange }) => (
  <SwitchContainer>
    入力を許可
    <SwitchInput
      type="checkbox"
      checked={checked}
      onChange={onChange}
      id="switch"
    />
    <SwitchLabel isOff={checked} htmlFor="switch">
      <SwitchKnob isOff={checked}/>
    </SwitchLabel>
  </SwitchContainer>
);

export {
    Switch
}