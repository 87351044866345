import styled from "styled-components";

export const BackgroundDark = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;
  height: ${({ setHeight }) => setHeight || 'calc(100vh - 120px)' };
  margin-top: 115px;
  /* background: ${({ theme }) => theme.darkBg}; */
  padding: 0 1.5rem;
  border-radius: 12px;
`