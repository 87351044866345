import React, { useEffect, useRef, useState } from "react";
import { convertFullWidthToHalfWidth } from "../../utils/commonUtils";

const InputNumberCustom = (props) => {
  const [value, setValue] = useState("");
  const inputRef = useRef();
  useEffect(() => {
    setValue(props?.value);
    // eslint-disable-next-line react/destructuring-assignment
  }, [props.value]);

  const prepareValue = (str) => convertFullWidthToHalfWidth(str);

  const handleOnBlur = (e) => {
    const val = prepareValue(e.target.value);
    setValue(val);
    if (props?.onChange) {
      props?.onChange(value);
    }
    if (props?.onBlur) {
      props?.onBlur(val);
    }
  };

  const handleOnChange = (e) => {
    const val = e.target.value;
    setValue(val);
    if (props?.onChange) {
      props?.onChange(value);
    }
  };

  return (
    <input
      {...props}
      ref={inputRef}
      value={value}
      onBlur={handleOnBlur}
      onChange={handleOnChange}
    />
  );
};

export default InputNumberCustom;
