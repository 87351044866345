import styled from "styled-components";
import Background from '../../assets/images/login-bg.png';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
  background-image: url(${Background});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  font-weight: 500;
`;

export const Wrapper = styled.div`
  /* position: absolute;
  right: 10%;
  top: 50%;
  transform: translate(-10%, -50%); */
  min-width: 435px;
  min-height: 410px;
  box-sizing: border-box;
  font-weight: 500;

  img {
    width: 308px;
    margin: 0 auto;
  }
`;

export const FormContent = styled.div`
  margin-top: 3rem;
  box-shadow: 6px 6px 60px rgba(3, 13, 99, 0.08);
  border-radius: 24px;
  /* background: ${({theme}) => theme.textDefault}; */
  background:  #D0CFD3;
  width: 100%;
  height: 410px;
  padding: 2rem 3rem;
  font-weight: 500;

  p, h2 {
    color: ${({ theme }) => theme.textDarkBlue};
    font-weight: 500;
  }

  p {
    font-size: 14px;
    margin-bottom: 0;
  }

  label {
    color: ${({ theme }) => theme.textGrey};
    font-size: 14px;
    font-weight: 500;
  }

  button {
    font-size: 14px;
    font-weight: 500;
    height: 40px;
  }

  & .input-icon {
    width: 100%;
    display: flex;
    align-items: center;
    border: 1px solid #DFE0F0;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 5px;
    background: #FCFCFF;
  }

  & .input-icon img {
    width: 20px;
    height: 100%;
  }

  & .input-icon input {
    width: 100%;
    padding: 8px 9px;
    background-color: transparent;
    appearance: none;
    font-size: 14px;
    font-weight: 500;
    outline: none;
    border: 0;
    box-shadow: none;
    color: ${({ theme }) => theme.textGrey};
  }

  input::placeholder {
    color: #DFE0F0;
    font-size: 14px;
    font-weight: 500;
  }
  .btn_login-new{
    background: #E37713;
    border-radius: 24px;
    outline: none;
    border: none;
    margin-top: 70px !important;
  }
`

export const ScoringContent = styled.div`
  margin-top: 53px;
  & .line-word {
    font-size: 12px;
    font-weight: 400;
    color: ${({ theme }) => theme.darkPrimaryColor};
    width: 100%; 
    overflow: hidden;
    text-align: center;
  }

  & .line-word:before,
  & .line-word:after {
    background-color: #DFE0F0;
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
  }

  & .line-word:before {
    right: 0.5em;
    margin-left: -50%;
  }

  & .line-word:after {
    left: 0.5em;
    margin-right: -50%;
  }
`
export const WatermarkSignIn = styled.div`
 position: absolute;
 width: 100%;
 height: 100%;
 top: 0;
 left: 0;
 background: none ;
 z-index: 0;
`