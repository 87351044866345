/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { CustomButton } from './style';

const ButtonUI = ({ newHeight, newWidth, sizeIcon, color, isLoading, children, disabled, ...props }) => (
  <CustomButton 
    setcolor={color || "main"}
    disabled={disabled}
    sizeicon={sizeIcon}
    newwidth={newWidth}
    newheight={newHeight}
    {...props}
  >
    {isLoading ? <i className="fa fa-spinner fa-spin fa-fw" /> : ''}&nbsp;
    {children || 'Submit'}
  </CustomButton>
);

ButtonUI.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.any,
  disabled: PropTypes.bool
};

ButtonUI.defaultProps = {
  isLoading: true,
  children: 'ok',
  disabled: true
};
export default ButtonUI;