/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import styled from "styled-components";
import { Button } from "reactstrap";

export const CustomButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: ${({ newwidth }) => newwidth || '90'}px;
  height:  ${({ newheight }) => newheight || '38'}px;

  box-shadow: none;
  outline: none;
  background-color: ${({ theme, setcolor }) => setcolor === "main" ? theme.mainColor : setcolor};
  border-radius: 6px;
  border: none;
  overflow: hidden;
  text-overflow: ellipsis;
  
  padding: 0.5rem .5rem;
  border: 1px solid ${({ theme, setcolor }) => setcolor === "main" ? theme.mainColor : setcolor};
  color: ${({ theme, setcolor }) => setcolor === "main" ? theme.borderColor : theme.textDefault};
  font-size: 14px;
  font-weight: 500;
  transition: all 0.5s ease-out;

  &:hover,
  &:focus,
  &:active {
    outline: none !important;
    box-shadow: none !important;
    border: ${({ theme, setcolor }) => setcolor === 'main' ? `1px solid ${theme.borderColor}` : `1px solid ${theme.textDefault}`} !important;;
    background-color: ${({ theme, setcolor }) => setcolor === "main" ? theme.mainColor : setcolor} !important;
    & .icon__btn {
      filter: brightness(5);
    }
  }

  &.disabled {
    pointer-events: none;
  }

  &:hover,
  &.active__btn {
    border: ${({ theme, setcolor }) => setcolor === 'main' ? `1px solid ${theme.borderColor}` : `1px solid ${theme.textDefault}`};
    background-color: ${({ theme, setcolor }) => setcolor === "main" ? theme.mainColorHover : theme.primaryColor};
    color: ${({ theme }) => theme.textDefault};
  }

  & .icon__btn {
    width: ${({ sizeicon }) => sizeicon || '32'}px;
    height: auto;
  }
`