import Select from "react-select";
import React from "react";

export const SelectSystem = ({ funcOnChange, systemCode }) => {
  const option = [
    { value: "1", label: "SANARU" },
    { value: "2", label: "加盟校" }
  ];
  return (
    <Select
      id="systemCode"
      name="systemCode"
      onChange={funcOnChange}
      // onMouseEnter={() => setWatermark(0)}
      value={option?.find((item) => item.value === systemCode)}
      // className="form-control"
      style={{
        WebkitAppearance: "auto",
        marginBottom: "5px",
        position: "relative",
        zIndex: "1"
      }}
      options={option}
    />
  );
};
