
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useCreatePortal } from '../hooks/usePortal';
import { LoadingNew } from './Loading';

export const LoadingGlobal = () => {
  const PortalLoading = useCreatePortal(document.body);
  const [loadingEffect, setLoadingEffect] = useState(false);
  const { loading } = useSelector((state) => state.common);

  useEffect(() => {
    if (loading) {
      setLoadingEffect(true);
    } else {
      setLoadingEffect(false);
    }
  }, [loading]);

  return (
    loadingEffect && (
      <PortalLoading>
        <LoadingNew />
      </PortalLoading>
    )
  );
};