import React from "react";
import styled from "styled-components";

const StyledPopup = {
    Popup: styled.div`
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 100;
        background-color: rgba(0, 0, 0, 0.5);
    `,
    PopupContent: styled.div`
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 500px;
        /* height: 300px; */
        background-color: #fff;
        border-radius: 5px;
    `,
    Title: styled.div`
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 20px;
        padding: 10px 20px;
        /* border-bottom: 1px solid #ccc; */
        /* background: #ccc;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px; */
    `,
    Content: styled.div`
        font-size: 16px;
        margin-bottom: 20px;
        padding: 0 20px;
    `,
    WrapEnd: styled.div`
        display: flex;
        justify-content: flex-end;
        gap: 10px;
        padding: 15px;
        border-top: 1px solid #ccc;
        button{
            padding: 5px 10px;
            border-radius: 5px;
            &:hover{
                background: #ccc;
                color: #fff;
            }
        }
    `
}
export const PopupNew = (
 {
    title,
    content,
    submit,
    cancel,
    submitText = "はい",
    cancelText = "いいえ"
 }
) => {
    const test = '1';
    return (
        <StyledPopup.Popup>
            <StyledPopup.PopupContent>
                <StyledPopup.Title>{title}</StyledPopup.Title>
                <StyledPopup.Content>{content}</StyledPopup.Content>
                <StyledPopup.WrapEnd>
                    <form onSubmit={e => {
                        e.preventDefault();
                        submit();
                    }}>
                    <button 
                      type='submit'
                      onTouchStart={submit}
                      onTouchEnd={submit}
                    >{submitText}</button>
                   
                    </form>
                    <button type="button" onClick={cancel}>{cancelText}</button>
                </StyledPopup.WrapEnd>
            </StyledPopup.PopupContent>
        </StyledPopup.Popup>
    )
}