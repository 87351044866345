/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-onchange */
import Select from 'react-select';
import React, {useMemo} from "react";
import Storage from "../../../utils/storega";

export const SelectPeriod = ({ data, periodCode, setPeriodCode }) => {
 
  // const options = useMemo(() => 
  //   data?.map((item) => (
  //       <option key={item?.periodCode} value={item?.periodCode}>
  //           {item?.periodName}
  //       </option>
  //   )), [data]
  // )
 const options = useMemo(() =>
    data?.map((item) => ({
      value: item?.periodCode,
      label: item?.periodName
    })),
    [data]
  );


  return (
    <>
    <label htmlFor="periodCode" className="form-label"> 時限 </label>
      {/* <select
        id="periodCode"
        name="periodCode"
        onChange={(event) => {
            setPeriodCode(event.target.value);
        }}
        value={periodCode}
        className="form-control"
        style={{ WebkitAppearance: "auto", marginBottom: "5px" }}

      >
        {options}
      </select> */}
      <Select
        id="periodCode"
        name="periodCode"
        onChange={(event) => {
            setPeriodCode(event.value);
        }}
        value={
          options?.find((item) => item.value === periodCode)

        }
        style={{ WebkitAppearance: "auto", marginBottom: "5px" }}
        options={options}
        placeholder="選択..."
        noOptionsMessage={() => "該当データなし"}
      />

    </>
  );
};
