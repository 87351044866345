import React from "react";
import styled from "styled-components";
import { media } from "../index";

const COLORS = {
  default: '#00AECB',
  active: '#fff'
}
const StyledItem = {
  Button: styled.div``,
  User: styled.div`
    height: 80px;
    color: ${({isLoginUser = false}) => isLoginUser ? COLORS.active : COLORS.default};
    position: relative;
    font-family: "Yu Gothic UI";
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    overflow: hidden;
   
    ${media.tabletMax`
     font-size: 26px;
    `}
    border: 2px solid rgba(255, 255, 255, 0);
    text-align: center;
    text-shadow: 0px 0px 10px #000000;
    border-radius: 0;
    backdrop-filter: blur(5px);
  
    max-height: 80px;
    max-width: 350px;
    cursor: pointer;
    border-radius: 50px;
    border: 1px solid;

    display: flex;
    justify-content: center;
    align-items: center;
   
    &:hover,
    &:focus,
    &:active,
    &:visited {
      background: rgba(255, 255, 255, 0.9);
      color: #000;
      text-shadow: none;
    }
    .user_sub-content{
      padding: 5px 20px;
      height: fit-content;
      display: -webkit-box;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow-wrap: break-word;
      overflow: hidden
    }
  `
};

const Button = ({ content = "", ...props }) => (
  <StyledItem.Button {...props}>{content}</StyledItem.Button>
);
const User = ({ content = "", ...props }) => (
  <StyledItem.User  {...props}>
    {/* <p>{content}</p> */}
    <div className="user_sub-content">
    {content}
    </div>
  </StyledItem.User>
);
export const Item = {
  Button,
  User
};
