/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import styled from "styled-components";
import Delete from "../assets/delete.png";

const StyledCaculator = {
  Layout: styled.div`
    width: 374px;
  `,
  Button: styled.div`
    cursor: pointer;
    width: 85px;
    height: 85px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.75);
    border: 2px solid #ffffff;
    backdrop-filter: blur(5px);

    font-family: "Yu Gothic UI";
    font-style: normal;
    font-weight: 350;
    font-size: 50px;
    line-height: 80px;
    color: #ffffff;
    text-align: center;
    &:hover {
      background: rgba(62, 61, 61, 0.5);
    }
    ${({ mg }) => mg && `margin: ${mg};`}
  `,
  Layout1to9: styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 17px;
  `,
  LayoutFeature: styled.div`
    display: flex;
    justify-content: ${({ jc }) => jc || "space-between"};
    ${({ mg }) => mg && `margin: ${mg};`}
    ${({gap}) => gap && `gap: ${gap};`}
  `
};
const InputKeyboard = styled.div`
 position: relative;
 z-index: 1;
 &.active {
    border: 3px solid #FF0000;
 }
`
const ButtonClear = styled(StyledCaculator.Button)`
       border-radius: 5px;
    font-size: 24px;
    height: 60px;
    margin-top: 12px;
    line-height: 56px;
`

const KeyboardCaculatorNoFunc = ({
    chooseInput,
setSchoolIdValue,
setSchoolBuildingValue,
handleClear
}) => {
  const { Button, Layout1to9, LayoutFeature, Layout } = StyledCaculator;
    
  const setValue = (value) => {
    if (value === "clear") {
     console.log("clear")
    
    } else if (value === "submit") {
      console.log("submit")
    } else if (value === "delete") {
       chooseInput === 1 ? setSchoolIdValue(prev => prev.slice(0, -1)) : chooseInput === 2 ? setSchoolBuildingValue(prev => prev.slice(0, -1)) : null
    }
     else {
     chooseInput === 1 ? setSchoolIdValue(prev => prev + value) : chooseInput === 2 ? setSchoolBuildingValue(prev => prev + value) : null
    }
  };

  const UI1To9 = (
    <Layout1to9>
      {Array.from({ length: 9 }, (_, index) => (
        <Button className="btn_disabled_blur" onClick={() => setValue(index + 1)} key={index}>
          {index + 1}
        </Button>
      ))}
    </Layout1to9>
  );

  return (
    <Layout>
      {UI1To9}
      <LayoutFeature jc="end" mg="17px auto" gap="17px">
     
        <ButtonClear onClick={handleClear}>クリア</ButtonClear>
        <Button  onClick={() => setValue(0)} className="btn_disabled_blur">
          0
        </Button>
             <img className="btn_disabled_blur" src={Delete} alt="" onClick={() => setValue("delete")} style={
                {
                    width: '84px',
                    cursor: 'pointer',
                    margin: 0
                }
             }/>
         
      </LayoutFeature>

      {/* <LayoutFeature>
        <ButtonFeature onClick={() => setValue("clear")}>クリア</ButtonFeature>
        <ButtonFeature onClick={() => setValue("submit")}>決定</ButtonFeature>
      </LayoutFeature> */}
    </Layout>
  );
};

export { KeyboardCaculatorNoFunc, InputKeyboard};
