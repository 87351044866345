/* eslint-disable no-unused-expressions */
import lodash from 'lodash';
import { stringify } from 'query-string';
import { pushNotify } from '../../components/notifycation';
import { getEnv } from "../../configs/env";
import { messageError, MESSAGE_ERROR_WEB } from '../../constant/messageError';
import { params } from "../../utils/commonUtils";
import { callApi, callApiNoToken, newCallApi, clearPrResolve } from "../../utils/request";
import Storage from "../../utils/storega";
import { TYPES } from "./index";
import { setLoadingApp } from './common';


const updateStatus = (status, statusInfo) => ({
  type: status,
  data: statusInfo
});

const getCurrentLesson = (payload) => {
  let url = `${getEnv('REACT_APP_API_SERVER')}/auth/user-lesson-course/current-lesson`;
  if (!!payload.params && Object.keys(payload.params).length)
    url = `${url}?${params(payload.params)}`;

  const config = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${Storage.get("USER_ACCESS_TOKEN")}`
    }
  };
  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_CURRENT_LESSON_REQUEST));
    callApi(
      url,
      config,
      null,
      (data) => {
        dispatch(updateStatus(TYPES.GET_CURRENT_LESSON_SUCCESS, { currentLesson: data.data }));
      },
      (err) => {
        dispatch(updateStatus(TYPES.GET_CURRENT_LESSON_FAILED, { error: err }));
      }
    );
  };
}

const getLessonCourse = () => {
  const config = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${Storage.get("USER_ACCESS_TOKEN")}`
    }
  };
  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_LESSON_COURSE_REQUEST));
    callApi(
      `${getEnv('REACT_APP_API_SERVER')}/auth/lesson-course`,
      config,
      null,
      (data) => {
        dispatch(updateStatus(TYPES.GET_LESSON_COURSE_SUCCESS, { lessonCourse: data.data }));
      },
      (err) => {
        dispatch(updateStatus(TYPES.GET_LESSON_COURSE_FAILED, { error: err }));
      }
    );
  };
}

const getInforRoadMap = (payload, cb = () => {}) => {
  let url = `${getEnv('REACT_APP_API_SERVER')}/auth/road-map`;
  if (!!payload.params && Object.keys(payload.params).length)
    url = `${url}?${params(payload.params)}`;

  const config = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${Storage.get("USER_ACCESS_TOKEN")}`
    }
  };
  return (dispatch) => {
    dispatch(updateStatus('LOADING_ON'))
    dispatch(updateStatus(TYPES.GET_RAOD_MAP_REQUEST));
    callApi(
      url,
      config,
      null,
      (data) => {
        cb(data.data)
        dispatch(updateStatus(TYPES.GET_RAOD_MAP_SUCCESS, { roadMap: data.data }));
        dispatch(updateStatus('LOADING_OFF'))
      },
      (err) => {
        dispatch(updateStatus(TYPES.GET_RAOD_MAP_FAILED, { error: err }));
        dispatch(updateStatus('LOADING_OFF'))
      },
      () => {},
      {
        isHaveLogParams: true
      }
    );
  };
};
const updateStatusGenExam = ({
  examId, status, isNormalExam, cbEvent = () => {}
}) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/auth/generate-exam/generate-exam-status`;
  const config = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${Storage.get("USER_ACCESS_TOKEN")}`
    },
    body: JSON.stringify({
      examId,
      status,
      isNormalExam
    })
  }
  return (dispatch) => {
    callApi(
      url,
      config,
      null,
      (data) => {},
      (err) => {},
      cbEvent,
      {
        isHaveLogParams: true,
        isFirstParams: true
      }
    );
  }
}
const generationExam = (payload, onFinal = () => {}, onError = () => {}, onSuccess = () => {}) => {
  const { examId, typeGenerate } = payload?.params;
  // New flow - Updated at 16-11-2022
  const urlAnswer = `${getEnv('REACT_APP_API_SERVER')}/auth/generate-exam/${examId}?type=${typeGenerate}`
  const urlSurprise = `${getEnv('REACT_APP_API_SERVER')}/auth/surprise-exam/generate?examId=${examId}&type=${typeGenerate}`
  // Old flow 
  // let url = `${getEnv('REACT_APP_API_SERVER')}/auth/user-lesson-course/generate-exam-file-v3`;
  // if (!!payload.params && Object.keys(payload.params).length)
  //   url = `${url}?${params(payload.params)}`;

  const config = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${Storage.get("USER_ACCESS_TOKEN")}`
    }
  };
  const configSurprise = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${Storage.get("USER_ACCESS_TOKEN")}`
    }
  }

  return (dispatch) => {
    dispatch(updateStatus('LOADING_ON'))
    dispatch(updateStatus(TYPES.GENERATION_EXAM_REQUEST));
    callApi(
      // printAnswer ? urlAnswer : url,
      payload?.type === 'surprise' ? urlSurprise : urlAnswer,
      payload?.type === 'surprise' ? configSurprise : config,      
      null,
      (data) => {
        onSuccess()
        dispatch(updateStatus(TYPES.GENERATION_EXAM_SUCCESS, { exam: data.data }));
      },
      (err) => {
        const keyError = messageError[err?.errors?.message] || MESSAGE_ERROR_WEB.CANNOT_GENERATE_EXAM
        pushNotify("error", keyError)
        dispatch(updateStatus(TYPES.GENERATION_EXAM_FAILED, { error: err }));
        dispatch(updateStatus('LOADING_OFF'))
        onError()
      },
      onFinal, ({
        pushLog: false,
        // isFakeRes: true
        isHaveLogParams: true,
        isFirstParams: false
      }),
    );
  };
}

const checkCreateFirstExam = (payload, onSuccess = () => {}) => {
  let url = `${getEnv('REACT_APP_API_SERVER')}/auth/user-lesson-course/create-first-exam`;
  if (!!payload.params && Object.keys(payload.params).length)
    url = `${url}?${params(payload.params)}`;

  const config = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${Storage.get("USER_ACCESS_TOKEN")}`
    }
  };
  return (dispatch) => {
    dispatch(updateStatus('LOADING_ON'))
    dispatch(updateStatus(TYPES.CHECK_CREATE_FIRST_EXAM_REQUEST));
    callApi(
      url,
      config,
      null,
      (data) => {
        dispatch(updateStatus(TYPES.CHECK_CREATE_FIRST_EXAM_SUCCESS, { createkExam: data.data }));
        dispatch(setLoadingApp(false))
        onSuccess(data.data)
      },
      (err) => {
        dispatch(updateStatus(TYPES.CHECK_CREATE_FIRST_EXAM_FAILED, { error: err }));
        dispatch(updateStatus('LOADING_OFF'))
      },
      () => {},
      {
        isHaveLogParams: true
      }
    );
  };
}

const checkCreateExam = (payload) => {
  let url = `${getEnv('REACT_APP_API_SERVER')}/auth/user-lesson-course/create-next-exam`;
  if (!!payload.params && Object.keys(payload.params).length)
    url = `${url}?${params(payload.params)}`;

  const config = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${Storage.get("USER_ACCESS_TOKEN")}`
    }
  };
  return (dispatch) => {
    dispatch(updateStatus(TYPES.CHECK_CREATE_EXAM_REQUEST));
    callApi(
      url,
      config,
      null,
      (data) => {
        dispatch(updateStatus(TYPES.CHECK_CREATE_EXAM_SUCCESS, { createkExam: data.data }));
      },
      (err) => {
        dispatch(updateStatus(TYPES.CHECK_CREATE_EXAM_FAILED, { error: err }));
      }
    );
  };
}

const checkResult = (payload, cbEventSus = () => {}) => {
  let url = `${getEnv('REACT_APP_API_SERVER')}/auth/user-lesson-course/result-lesson`;
  if (!!payload.params && Object.keys(payload.params).length)
    url = `${url}?${params(payload.params)}`;

  const config = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${Storage.get("USER_ACCESS_TOKEN")}`
    }
  };
  return (dispatch) => {
    dispatch(updateStatus('LOADING_ON'))
    dispatch(updateStatus(TYPES.CHECK_RESULT_LESSON_REQUEST));
    callApi(
      url,
      config,
      null,
      (data) => {
        dispatch(updateStatus(TYPES.CHECK_RESULT_LESSON_SUCCESS, { resultLesson: data.data }));
        cbEventSus(data.data)
      },
      (err) => {
        dispatch(updateStatus(TYPES.CHECK_RESULT_LESSON_FAILED, { error: err }));
      },
      () => dispatch(updateStatus('LOADING_OFF')),
      {
        isHaveLogParams: true
      }
    );
  };
}

const getExamDetail = (payload) => {
  let url = `${getEnv('REACT_APP_API_SERVER')}/auth/exam/detail`;
  if (!!payload.params && Object.keys(payload.params).length)
    url = `${url}?${params(payload.params)}`;

  const config = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${Storage.get("USER_ACCESS_TOKEN")}`
    }
  };
  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_EXAM_DETAIL_REQUEST));
    newCallApi(
      url,
      config,
      null,
      (data) => {
        dispatch(updateStatus(TYPES.GET_EXAM_DETAIL_SUCCESS, { examDetail: data.data }));
      },
      (err) => {
        dispatch(updateStatus(TYPES.GET_EXAM_DETAIL_FAILED, { error: err }));
      }
    );
  };
};

const getCommentAI = (payload) => {
  let url = `${getEnv('REACT_APP_API_SERVER')}/auth/exam/comment`;
  if (!!payload.params && Object.keys(payload.params).length)
    url = `${url}?${params(payload.params)}`;

  const config = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${Storage.get("USER_ACCESS_TOKEN")}`
    }
  };
  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_COMMENT_AI_REQUEST));
    newCallApi(
      url,
      config,
      null,
      (data) => {
        dispatch(updateStatus(TYPES.GET_COMMENT_AI_SUCCESS, { comment: data.data }));
      },
      (err) => {
        dispatch(updateStatus(TYPES.GET_COMMENT_AI_FAILED, { error: err }));
      }
    );
  };
};

const getSmallQuestion = (payload) => {
  let url = `${getEnv('REACT_APP_API_SERVER')}/auth/exam/question/small-question`;
  const urlSurprise = `${getEnv('REACT_APP_API_SERVER')}/auth/surprise-exam/question/small-question?examId=${payload?.params?.examCode}`;
  if (!!payload.params && Object.keys(payload.params).length)
    url = `${url}?${params(payload.params)}`;

  const config = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${Storage.get("USER_ACCESS_TOKEN")}`
    }
  };
  return (dispatch) => {
    dispatch(updateStatus('LOADING_ON'))
    dispatch(updateStatus(TYPES.GET_SMALL_QUESTION_REQUEST));
    callApi(
      payload?.type === 'surprise' ? urlSurprise : url,
      config,
      null,
      (data) => {
        data.data.questionInfo = lodash.orderBy(data.data.questionInfo, ['questionIndex'], ['asc']);
        dispatch(updateStatus(TYPES.GET_SMALL_QUESTION_SUCCESS, { smallQuestion: data.data }));
      },
      (err) => {
        dispatch(updateStatus(TYPES.GET_SMALL_QUESTION_FAILED, { error: err }));
      }, () => dispatch(updateStatus('LOADING_OFF')),
      {
        isHaveLogParams: true
      }
    );
  };
};

const sendScoreSmallQuestion = (payload, onError = () => {}) => {
  const config = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      // ...(payload?.type === 'surprise' ? { Authorization: `Bearer ${Storage.get("USER_ACCESS_TOKEN")}`} : {})
      Authorization: `Bearer ${Storage.get("USER_ACCESS_TOKEN")}`
    },
    body: JSON.stringify({
      examCode: payload.examCode,
      questions: payload.questions,
      examScore: payload.examScore,
      totalScore: payload.totalScore
    })
  };
  const handleCallback = (act) => payload?.cbEvent(act)
  const handleError = (err) => {
    err?.errors?.message === 'EXAM_HAS_BEEN_SCORED' && onError()
  }
  return (dispatch) => {
    dispatch(updateStatus('LOADING_ON'))
    dispatch(updateStatus(TYPES.SCORING_SMALL_QUESTION_REQUEST));
    // eslint-disable-next-line no-unused-expressions
    payload?.type === 'surprise' ? callApi(
    `${getEnv("REACT_APP_API_SERVER")}/auth/surprise-exam/scoring`,
    config,
    null,
    (data) => {
      dispatch(updateStatus(TYPES.SCORING_SMALL_QUESTION_SUCCESS, { score: data.data }));
      handleCallback(data.data)
    },
    (err) => {
      dispatch(updateStatus('LOADING_OFF'))
      dispatch(updateStatus(TYPES.SCORING_SMALL_QUESTION_FAILED, { error: err }));
      handleError(err)
      
    },
    () => {},
    {
      isHaveLogParams: true
    }
  ) :  callApi(
    `${getEnv("REACT_APP_API_SERVER")}/scoring`,
    config,
    null,
    (data) => {
      dispatch(updateStatus(TYPES.SCORING_SMALL_QUESTION_SUCCESS, { score: data.data }));
      handleCallback(data.data)
    },
    (err) => {
      dispatch(updateStatus('LOADING_OFF'))
      dispatch(updateStatus(TYPES.SCORING_SMALL_QUESTION_FAILED, { error: err }));
      handleError(err)
    }, () => {}, {
      // isFakeRes: true
      isHaveLogParams: true,
      isFirstParams: true
    }
  )
  };
};

const getAllRoadMap = (payload) => {
  let url = `${getEnv('REACT_APP_API_SERVER')}/auth/road-map/all`;
  if (!!payload.params && Object.keys(payload.params).length)
    url = `${url}?${params(payload.params)}`;

  const config = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${Storage.get("USER_ACCESS_TOKEN")}`
    }
  };
  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_ROAD_MAP_ALL_REQUEST));
    callApi(
      url,
      config,
      null,
      (data) => {
        dispatch(updateStatus(TYPES.GET_ROAD_MAP_ALL_SUCCESS, { listExams: data.data }));
      },
      (err) => {
        dispatch(updateStatus(TYPES.GET_ROAD_MAP_ALL_FAILED, { error: err }));
      },
      () => {},
      {
        isHaveLogParams: true
      }
    );
  };
};

const getQuestionByExamId = (payload) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/auth/exam/question/detail?examCode=${payload.examCode}`;

  const config = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${Storage.get("USER_ACCESS_TOKEN")}`
    }
  };
  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_LIST_QUESTION_BY_EXAMID_REQUEST));
    callApi(
      url,
      config,
      null,
      (data) => {
        dispatch(updateStatus(TYPES.GET_LIST_QUESTION_BY_EXAMID_SUCCESS, { questionByExamId: data.data }));
      },
      (err) => {
        dispatch(updateStatus(TYPES.GET_LIST_QUESTION_BY_EXAMID_FAILED, { error: err }));
      }
    );
  };
};

const clearRoadMap = () => (dispatch) => {
  dispatch(updateStatus(TYPES.CLEAR_DATA_SUCCESS));
};

const setLoading = (payload) => (dispatch) => {
  dispatch(
    updateStatus(TYPES.SET_LOADING, {
      isLoading : payload
    })
  );
}

const getListSchoolBuilding = (payload) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/auth/school-buildings?${stringify({
    isGetAll: true,
    systemCode: payload.systemCode,
    schoolId: payload.schoolId
  })}`;

  const config = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${Storage.get("USER_ACCESS_TOKEN")}`
    }
  };
  return () => {
    callApi(
      url,
      config,
      null,
      (r) => {
        payload?.success(r.data);
      },
      (err) => {
        payload?.error(err);
      },
      payload?.finally()
    );
  };
};

const createExamAfterScoring = (payload, handleSuccess = () => {}, handleError = () => {}) => {
  let url = `${getEnv('REACT_APP_API_SERVER')}/auth/user-lesson-course/create-first-exam`;
  if (!!payload.params && Object.keys(payload.params).length)
    url = `${url}?${params(payload.params)}`;

  const config = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${Storage.get("USER_ACCESS_TOKEN")}`
    }
  };
  return (dispatch) => {
    dispatch(updateStatus('LOADING_ON'))
    newCallApi(
      url,
      config,
      null,
      (data) => {
      handleSuccess(data?.data);
      },
      (err) => {
       handleError(err);
       dispatch(updateStatus('LOADING_OFF'))
      },
      {
        isHaveLogParams: true
      }
    );
  };
}

const getRoadmapAfterScoring = (payload, handleSuccess = () => {}, handleError = () => {}) => {
  let url = `${getEnv('REACT_APP_API_SERVER')}/auth/road-map`;
  if (!!payload.params && Object.keys(payload.params).length)
    url = `${url}?${params(payload.params)}`;

  const config = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${Storage.get("USER_ACCESS_TOKEN")}`
    }
  };
  return (dispatch) => {
   dispatch(updateStatus('LOADING_ON'))
    newCallApi(
      url,
      config,
      null,
      (data) => {
      handleSuccess(data?.data);
      // dispatch(updateStatus('LOADING_OFF'))
      },
      (err) => {
      handleError(err);
      dispatch(updateStatus('LOADING_OFF'))
      },
      {
        isHaveLogParams: true
      }
    );
  };
};

const updateLoginHistory = () => {
  const getCurrrenStudent = Storage.get('INFO_USER_LOGIN')
  const url = `${getEnv("REACT_APP_API_SERVER")}/auth/login/history?${stringify({
    schoolId: getCurrrenStudent?.schoolId,
    systemCode: getCurrrenStudent?.systemCode,
    timetableId: getCurrrenStudent?.timetableId,
    userId: getCurrrenStudent?.username
    })}`;
  const config = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${Storage.get("USER_ACCESS_TOKEN")}`
    }
    // body: JSON.stringify({
    //   schoolId : getCurrrenStudent.schoolId,
    //   systemCode : getCurrrenStudent.systemCode,
    //   timetableId : getCurrrenStudent.timetableId,
    //   userId : getCurrrenStudent.username
    // })
  };
  return (dispatch) => {
    callApi(
      url,
      config,
      null,
      async (data) => {
        await dispatch(updateStatus('UPDATE_LIST_USER_SUCCESS',{
          id: getCurrrenStudent?.username,
          data: {
            isCheckedSanaru: data.data.isCheckedSanaru
          }
      }))
      },
      (err) => {}
    );
  }
}
export {
  getInforRoadMap,
  getLessonCourse,
  generationExam,
  getCurrentLesson,
  checkCreateExam,
  checkCreateFirstExam,
  checkResult,
  getExamDetail,
  clearRoadMap,
  getCommentAI,
  getSmallQuestion,
  sendScoreSmallQuestion,
  getAllRoadMap,
  getQuestionByExamId,
  setLoading,
  getListSchoolBuilding,
  createExamAfterScoring,
  getRoadmapAfterScoring,
  updateStatusGenExam,
  updateLoginHistory
};
