const RouterPath = {
  HOME: {
    path: "/"
  },
  SIGN_IN: {
    path: "/sign-in"
  },
  // SCORING_MAP: {
  //   path: "/scoring/course/:courseCode/series/:seriesCode/subject/:subjectCode/classification/:classificationCode/largeUnit/:largeUnitCode/smallUnit/:smallUnitCode/lesson/:lessonCode",
  //   pathType: ({ courseCode, seriesCode, subjectCode, largeUnitCode, classificationCode, smallUnitCode, lessonCode }) =>
  //     `/scoring/course/${courseCode || '0'}/series/${seriesCode || '0'}/subject/${subjectCode || '0'}/classification/${classificationCode || '0'}/largeUnit/${largeUnitCode || '0'}/smallUnit/${smallUnitCode || '0'}/lesson/${lessonCode || '0'}`
  // },
  // SCORING_MAP_EXAM: {
  //   path: "/scoring/examId/:examId",
  //   pathType: ({ examId }) =>
  //     `/scoring/examId/${examId}`
  // },
  // SCORING_MAP_EXAM_EDIT: {
  //   path: "/scoring/examId/:examId/subject/:subjectCode",
  //   pathType: ({ examId, subjectCode }) =>
  //     `/scoring/examId/${examId}/subject/${subjectCode}`
  // },
  SCORING_MAP_NEW : {
    path: "/scoring"
  },
  STUDY_MODE: {
    path: "/study-mode"
  },
  SCHEDULE: {
    path: "/schedule"
  },
  SCHEDULE_DETIALS: {
    path: "/schedule/details/course/:courseCode/series/:seriesCode/subject/:subjectCode/classification/:classificationCode/largeUnit/:largeUnitCode/",
    pathType: ({ courseCode, seriesCode, subjectCode, largeUnitCode, classificationCode }) =>
      `/schedule/details/course/${courseCode}/series/${seriesCode}/subject/${subjectCode}/classification/${classificationCode}/largeUnit/${largeUnitCode}`
  },
  RANKING: {
    path: "/ranking/list"
  },
  DEMO_PAGE: {
    path: "/demo"
  }
};

export default RouterPath;
