/* eslint-disable import/no-unresolved */
/** @jsxRuntime classic */
import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './app/App';
import { BackgroundVideo } from './pages/CRs_T12_2022_HomePage/components/BackgroundVideo';
import { LoadingNew } from './pages/CRs_T12_2022_HomePage/components/Loading';
import reportWebVitals from './reportWebVitals';

const Background = lazy(() => import('./pages/CRs_T12_2022_HomePage/components/BackgroundVideo').then(
  res => ({ default: res.BackgroundVideo })
));
ReactDOM.render(
 <>
  <App />
  <Suspense fallback={<LoadingNew />}>
    <Background />
  </Suspense>
 </>,
  document.getElementById('root')
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
