/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prefer-const */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/react-in-jsx-scope */
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect } from "react";
import { Provider, useDispatch } from "react-redux";
import { BrowserRouter, useLocation } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { ThemeProvider } from "styled-components";
import { LoadingGlobal } from "../pages/CRs_T12_2022_HomePage/components/LoadingGlobal";
import Routes from "../routes/routes";
import { persistor, store } from "../store";
import theme from "../style/variables";
import "./App.scss";

const clearBrowserCache = (cb = () => {}) => {
  // Check if exist any value in localStorage
  /**
   * Sau này có mở tính năng lưu trữ thông tin thì sửa lại điều kiện, filter lọc ra các key cần lưu trữ
   * ex: const listKey = ["A", "B", "C"];
   * const isExistLocalStorageValue = localStorage.length && Object.keys(localStorage).filter(key => !listKey.includes(key)).length;
   */
  const excludeKeys = [
    "SCHOOL_ID",
    "SYSTEM_CODE",
    "LIST_PERIOD",
    "SCHOOL_BUILDING_LIST",
    "OLD_SYSTEM_CODE",
    "SELECT_MODE",
    "SCHOOL_BUILDING_ID",
    "PERIOD_ID",
    "persist:root" // redux-persist
  ];
  const isExistLocalStorageValue =
    localStorage.length &&
    Object.keys(localStorage).filter((key) => !excludeKeys.includes(key))
      .length;

  // const isExistLocalStorageValue = localStorage.length;
  // Check if exist any value in sessionStorage
  const isExistSessionStorageValue = sessionStorage.length;
  // Check if exist any value in Cache Storage
  const isExistCacheStorageValue = window.caches.keys().length;

  const isExistValue = Boolean(
    isExistLocalStorageValue ||
      isExistSessionStorageValue ||
      isExistCacheStorageValue
  );

  /**
   * Save the values
   * Save excludeKeys = ['SCHOOL_ID', 'SYSTEM_CODE', 'LIST_PERIOD', 'SCHOOL_BUILDING_LIST', 'OLD_SYSTEM_CODE', 'SELECT_MODE', 'SCHOOL_BUILDING_ID', 'PERIOD_ID'];
   */

  const listValue = {
    SCHOOL_ID: localStorage.getItem("SCHOOL_ID"),
    SYSTEM_CODE: localStorage.getItem("SYSTEM_CODE"),
    LIST_PERIOD: localStorage.getItem("LIST_PERIOD"),
    SCHOOL_BUILDING_LIST: localStorage.getItem("SCHOOL_BUILDING_LIST"),
    OLD_SYSTEM_CODE: localStorage.getItem("OLD_SYSTEM_CODE"),
    SELECT_MODE: localStorage.getItem("SELECT_MODE"),
    SCHOOL_BUILDING_ID: localStorage.getItem("SCHOOL_BUILDING_ID"),
    PERIOD_ID: localStorage.getItem("PERIOD_ID")
  };

  // Clear localStorage and sessionStorage
  localStorage.clear();
  sessionStorage.clear();

  /**
   * Restore the saved values of excludeKeys
   */
  Object.keys(listValue).forEach((key) => {
    if (listValue[key] !== null) localStorage.setItem(key, listValue[key]);
  });

  // Clear Cache Storage
  window.caches.keys().then((names) => {
    for (let name of names) caches.delete(name);
  });

  // Check if exist service worker
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      for (let registration of registrations) {
        registration.unregister();
      }
    });
  }
  if (isExistValue) {
    cb();
    setTimeout(() => {
      window.location.href = "/sign-in";
    }, 500);
  }
};

const updateStatus = (status, statusInfo) => ({
  type: status,
  data: statusInfo
});

const MiddleWare = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const mainMode = localStorage.getItem("MAIN_MODE");
  useEffect(() => {
    if (location.pathname === "/"
      //  || (location.pathname === "/sign-in" && !mainMode)
      ) {
      clearBrowserCache(() => {
        dispatch(updateStatus("LOADING_ON"));
      });
    }
  }, [location.pathname]);

  return null;
};

const App = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  // useEffect(() => {
  //   setTimeout(() => {
  //     setIsLoading(false);
  //   }, 600);
  // }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <div
              className="App"
              style={{
                // opacity: isLoading ? 0 : 1,
                width: "100%",
                height: "100%"
              }}
            >
              <Routes />
            </div>
            <MiddleWare />
            {/* {isLoading && <LoadingNew />} */}
          </ThemeProvider>
        </BrowserRouter>
      </PersistGate>
      <LoadingGlobal />
    </Provider>
  );
};

export default App;
