// https://bapjp.backlog.com/board/GAKUTORE?selectedIssueKey=GAKUTORE-2157 - GAKUTORE-2157 Học sinh có thể tự lựa chọn Lesson để học
import React, { useEffect } from "react";
import styled from "styled-components";
import Storage from "../../utils/storega";


const OPTIONS = [
  {
    value: "auto",
    label: "通常モード"
  },
  {
    value: "select",
    label: "生徒が選べるモード"
  }
];
const Wrapper = styled.div`
  margin-top: 8px;
`
const RadioGroup = styled.div`
 display: flex;
 gap: 10px;
 align-items: center;
 height: 26px;
`
const Radio = styled.div`
  cursor: pointer;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  border : 1px solid #fff;
  background: ${({ isActive = false }) => (isActive ? "#E37713" : "#fff")};
`
const Label = styled.div``

export const SelectMode = () => {
  const defaultMode = Storage.get("SELECT_MODE") || OPTIONS[0].value;
  const [valueOption, setOptionValue] = React.useState(defaultMode);
  
  const handleChange = (value) => {
    setOptionValue(value);
    Storage.set("SELECT_MODE", value);
  };
  
  const view = OPTIONS.map(({ value, label }) => (
    <RadioGroup key={value}>
       <Radio isActive={valueOption === value} onClick={() => handleChange(value)}/>
       <Label>{label}</Label>
    </RadioGroup>
  ));

  return <Wrapper>
    {view}
  </Wrapper>;
};
