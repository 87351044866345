/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-array-index-key */
import React, { useContext, useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import storage from "redux-persist/lib/storage";
import { useHistory } from "react-router-dom";
import { signIn } from "../../../store/actions/auth";
import { BackButton } from "../components/BackButton";
import { Item } from "../components/Item";
import { Logo } from "../components/Logo";
import { HomePageLayout, media } from "../index";
import Storage from "../../../utils/storega";
import { clearPrResolve } from "../../../utils/request";
import { methodApiNoAuth } from "../apis/noAuth";

const StyledIntroPage = {
  Layout: styled.div`
    height: calc(100% - 25vh);
    margin-top: 35px;
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 550px 1fr;
    ${media.desktopMin`
    grid-template-columns: 1fr 375px 1fr;
    `};
    gap: 5px;
    width: 100%;
  `,
  LayoutListUser: styled.div`
    color: #ffffff;
    display: grid;
    grid-template-columns: ${({ gtc }) => `repeat(${gtc}, 1fr)`};
    grid-template-rows: repeat(${({ gtr }) => gtr}, 1fr);
    row-gap: 20px;
    column-gap: 15px;
    place-self: flex-start;
    width: 100%;
    ${({ pdl }) =>
      pdl &&
      `
        margin-right: -10px;
        padding-left: ${pdl};
    `}
    ${({ pdr }) =>
      pdr &&
      `
        margin-left: -10px;
        padding-right: ${pdr};
    `}

  `
};
const ButtonNextPrev = styled.button`
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
`;
// const fakeData = Array(23).fill(0).map((item, index) => ({
//     id: index,
//     fullName: `Hello world 1 2 3 4  ${index + 1}`
// }
// ));
export const IntroPage = ({
  setLoading
}) => {
  const dispatch = useDispatch();
  const { isListEmpty, listUsers } = useSelector(state => state.auth);
  // const  listUsers  = fakeData
  const handleClick = (item) => {
    dispatch(
      signIn({
        passWord: "",
        username: item.username,
        schoolId: item.schoolId,
        // systemCode: item.systemCode,
        systemCode: Storage.get("SYSTEM_CODE"),
        // username: "27",
        // schoolId: "0001",
        // systemCode: 1,
        timetableId: item.timetableId,
        handleSuccess: () => {
          Storage.set("USER_NAME", item.fullName);
          Storage.set("INFO_USER_LOGIN", {
            passWord: "",
            username: item.username,
            schoolId: item.schoolId,
            systemCode: Storage.get("SYSTEM_CODE"),
            timetableId: item.timetableId
          });
          if(Storage.get("SELECT_MODE") === 'select'){
            Storage.set("MODE_SELECTED", 'LIST')
            Storage.set("TYPE_SELECT_MODE", 'LIST_LESSON')
          } else {
              Storage.set("TYPE_SELECT_MODE", 'NORMAL')
          }
        }
      })
    );
  };
  const newData = useMemo(() => {
    const result = {};
    listUsers?.forEach((item, index) => {
      const key = Math.floor(index / 42) + 1;
      if (!result[key]) {
        result[key] = [];
      }
      result[key].push(item);
    });
    return result;
  }, [listUsers]);
  const [page, setPage] = useState(1);
  const [showData, setShowData] = useState([]);
  useEffect(() => {
    setShowData(newData[page]);
  }, [newData, page]);
  useEffect(() => {
    if (
      listUsers.length === 0 &&
      !isListEmpty &&
      Storage.get("SYSTEM_CODE") &&
      Storage.get("SCHOOL_ID") !== null &&
      Storage.get("SCHOOL_BUILDING_ID") !== null &&
      Storage.get("PERIOD_ID") !== null &&
      Storage.get("LIST_PERIOD") !== null &&
      Storage.has("MAIN_MODE")
    ) {
      setLoading(true);
      dispatch(
        methodApiNoAuth.getListUserProfile({
          params: {
            systemCode: Storage.get("SYSTEM_CODE"),
            schoolCode: Storage.get("SCHOOL_ID"),
            schoolBuildingCode: Storage.get("SCHOOL_BUILDING_ID"),
            periodCode: Storage.get("PERIOD_ID")
          },
          success: (res) => {
           
          },
          final: () => setLoading(false)
        })
      );
    }
  }, []);
  const showListUser = useMemo(() => {
    let listLeft = [];
    let listRight = [];
    let gtr = 5;
    let gtc = 2;
    if(showData) {
      if (showData.length < 21 ){
        listLeft = showData.slice(0, 10);
       listRight = showData.slice(10, 20);
       gtr = 5;
       gtc = 2
     }
     if (showData.length > 20 && showData.length < 29) {
       listLeft = showData.slice(0, 14);
       listRight = showData.slice(14, 28);
       gtr = 6;
       gtc = 2
     }
     if (showData.length > 28) {
       listLeft = showData.slice(0, 21);
       listRight = showData.slice(21, 42);
       gtr = 6;
       gtc = 3
     }
    }

    const Left = (
      <StyledIntroPage.LayoutListUser gtr={gtr} pdl="10px" gtc={gtc}>
        {listLeft.map((item, index) => (
          <Item.User
            onClick={() => handleClick(item)}
            key={`left_${index}`}
            content={item.fullName}
            isLoginUser={item?.isCheckedSanaru}
          />
        ))}
      </StyledIntroPage.LayoutListUser>
    );
    const Right = (
      <StyledIntroPage.LayoutListUser gtr={gtr} pdr="10px" gtc={gtc}>
        {listRight.map((item, index) => (
          <Item.User
            onClick={() => handleClick(item)}
            key={`right_${index}`}
            content={item.fullName}
            isLoginUser={item?.isCheckedSanaru}
          />
        ))}
      </StyledIntroPage.LayoutListUser>
    );
    return { Left, Right };
  }, [showData]);
  const history = useHistory();
  const logoutNew = () => {
    clearPrResolve();
    Storage.remove("MAIN_MODE");
    history.replace("/sign-in");;
    // history.replace("/sign-in");
  };
  return (
    // <HomePageLayout>
    <>
      <BackButton onClick={logoutNew} />
      <StyledIntroPage.Layout>
        {showListUser.Left}
        <Logo />
        {showListUser.Right}
      </StyledIntroPage.Layout>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
          padding: "0 10px",
          position: "absolute",
          bottom: "10px",
          gap: "10px"
        }}
      >
        {listUsers.length > 40 ? (
          <>
            <ButtonNextPrev
              onClick={() => setPage(page - 1)}
              disabled={page === 1}
            >
              前
            </ButtonNextPrev>
            <ButtonNextPrev
              onClick={() => setPage(page + 1)}
              disabled={page === Object.keys(newData).length}
            >
              次
            </ButtonNextPrev>
          </>
        ) : null}
      </div>
    </>
    // </HomePageLayout>
  );
};
