/* eslint-disable no-console */
import { stringify } from "query-string";
import { toast } from "react-toastify";
import { getEnv } from "../../../configs/env";
import { TYPES } from "../../../store/actions";
import { callApi } from "../../../utils/request";
import Storage from "../../../utils/storega";

const updateStatus = (status, statusInfo) => ({
  type: status,
  data: statusInfo
});

const apiNoAuth = {
  timeTables: ({ params }) => `auth/user/timetable?${stringify(params)}`,
  userProfile: ({ params }) => `auth/user/profile?${stringify(params)}`
};
const methodApiNoAuth = {
     getListTimeTables: ({
        params = {},
        success = () => {},
        failed = () => {},
        final = () => {}
     }) => {  
        const config = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${Storage.get("USER_ACCESS_TOKEN")}`
          }
        };
        const api = `${getEnv('REACT_APP_API_SERVER')}/${apiNoAuth.timeTables({ params })}`;
        return (dispatch) => {
          dispatch(updateStatus('LOADING_ON'))
          callApi(
            api,
            config,
            null,
            (data) => {
             success(data);
            },
            (error) => {
             failed(error);
            },
            () => {
              dispatch(updateStatus('LOADING_OFF'))
              final()
            },
            {
              isHaveLogParams: true
            }
          );
        };
      },
     getListUserProfile: ({
      params = {},
      success = () => {},
      failed = () => {},
      final = () => {}

     }) => {  
        const config = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${Storage.get("USER_ACCESS_TOKEN")}`
          }
        };
        const api = `${getEnv('REACT_APP_API_SERVER')}/${apiNoAuth.userProfile({ params })}`;
        return (dispatch) => {
          dispatch(updateStatus('LOADING_ON'))
          callApi(
            api,
            config,
            null,
            (data) => {
             success(data);
             dispatch(updateStatus('GET_LIST_USER_SUCCESS', {
              listUsers: data?.data?.content
              })
            );

            },
            (error) => {
             const condition = !window.navigator?.onLine;
             if(condition){
              toast.error("インターネットがない");
             }
             failed(error);
            },
            () => {
              dispatch(updateStatus('LOADING_OFF'))
              final()
            }
          );
        };
      }
      
}
export { apiNoAuth, methodApiNoAuth};
