/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable jsx-a11y/no-onchange */
/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { useState, useMemo , useCallback, useEffect, useRef, useLayoutEffect} from 'react';
import { useLocation, Redirect, useHistory } from "react-router-dom";
import * as yup from "yup";
import { Input, FormFeedback, FormGroup, Button } from "reactstrap";
import { useDispatch } from 'react-redux';
import useMyForm from "../../hook/useMyForm";
import { getEnv } from "../../configs/env";
import InputIcon from '../../assets/images/icon-input.svg';
import { BtnDetail } from '../schedule-details/style';
import {  Wrapper, FormContent, WatermarkSignIn } from './style';
import Storage from "../../utils/storega";
import {
  callApiNoToken
} from "../../utils/request";
import { HomePageLayout } from '../CRs_T12_2022_HomePage/index';
import { methodApiNoAuth } from '../CRs_T12_2022_HomePage/apis/noAuth';
import { SelectPeriod } from '../CRs_T12_2022_HomePage/components/SelectPeriod';
import { IntroPage } from '../CRs_T12_2022_HomePage/pages/IntroPage';
import { InputKeyboard, KeyboardCaculatorNoFunc } from '../CRs_T12_2022_HomePage/components/CaculatorNoFunc';
import { useClickOutside } from '../CRs_T12_2022_HomePage/hooks/useClickOutside';
import { useOutsideMultipleDom } from '../CRs_T12_2022_HomePage/hooks/useOnMover';
import { LoadingNew } from '../CRs_T12_2022_HomePage/components/Loading';
import { Switch } from '../CRs_T12_2022_HomePage/components/SwitchButton';
import { CustomSelect } from '../CRs_T12_2022_HomePage/components/SelectCustom';
import { SelectSchoolBuilding } from './SelectSchoolBuilding';
import { getListSchoolBuilding } from '../../store/actions/roadMap';
import { SelectSystem } from './SelectSystem';
import { setLoadingApp } from '../../store/actions/common';
import { SelectMode } from './signInModeSelectLesson';
import InputNumberCustom from '../../components/InputNumber';

const fakePromise = () => new Promise((resolve, reject) => {
  setTimeout(() => {
    reject(new Error('504 Gateway Time-out'));
  }, 1000);
});

const clearAllCache = () => {
  const allKeys = Object.keys(localStorage);
  const excludeKeys = ['SCHOOL_ID', 'SYSTEM_CODE', 'LIST_PERIOD', 'SCHOOL_BUILDING_LIST', 'OLD_SYSTEM_CODE', 'SELECT_MODE', 'SCHOOL_BUILDING_ID', 'PERIOD_ID'];
  allKeys.forEach((key) => {
    if (!excludeKeys.includes(key)) {
      Storage.remove(key);
    }
  });
};

const SignIn = ({ token, status, signIn, ...props }) => {

  const getSearch = new URLSearchParams(useLocation().search);
  const dispatch = useDispatch();

  //* BK : 1/12/2021 
  // const schoolId = getSearch.get('schoolId');
  const schoolId = Storage.get('SCHOOL_ID');
  const schoolBuildingId = Storage.get('SCHOOL_BUILDING_ID');
  const periodId = Storage.get('PERIOD_ID');
  const listPeriod = Storage.get('LIST_PERIOD');
  const [isMode, setIsMode] = useState('input');
  const [schoolIdValue, setSchoolIdValue] = useState('');
  const [isConfirm, setIsConfirm] = useState(false);
  const [isShowMsg, setIsShowMsg] = useState(false);
  const [msgApiMaintain, setMsgApiMaintain] = useState('');
  //* 6-1
  const [systemCode, setSystemCode] = useState('1');
  
  const [periodList, setPeriodList] = useState([]);
  const [periodCode, setPeriodCode] = useState('');
  const [schoolBuildingValue, setSchoolBuildingValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [detailUser, setDetailUser] = useState({});
  const [chooseInput, setChooseInput] = useState(0);
  const [state, setState] = useState({
    isLoading: false,
    errors: [],
    resp: null
  });
  
  const [turnOffKeyboard, setTurnOffKeyboard] = useState(true);
  const [optSchoolBuilding, setOptSchoolBuilding] = useState([]);

  const location = useLocation();

  const isAtSignInPage = () => location.pathname === '/sign-in';
  const isLoginPage = () => Boolean(!localStorage.getItem('MAIN_MODE'));
  
  const clearCacheIfNeeded = () => {
    if (isAtSignInPage() && isLoginPage()) {
      clearAllCache();
    }
  };
  
  // useEffect(() => {
  //   window.addEventListener('load', clearCacheIfNeeded);
  //   window.addEventListener('beforeunload', clearCacheIfNeeded);
  
  //   return () => {
  //     window.removeEventListener('load', clearCacheIfNeeded);
  //     window.removeEventListener('beforeunload', clearCacheIfNeeded);
  //   };
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [location]);

// call fakePromise 

  const schema = useMemo(
    () =>
      yup.object().shape({
        userId: yup.string().required("Please enter UserID").trim()
      }),
    []
  );
  // const { register, errors, onSubmit, formState, watch } = useMyForm({
  //   validationSchema: schema,
  //   api: (formData) => signIn(schoolId ? {...formData, schoolId, schoolBuildingId, username: detailUser?.username} : formData)
  // });
  const history = useHistory()
  const handleSelectMode = (event) => {
    event.preventDefault();
    if (systemCode === '1') {
      Storage.set("SCHOOL_ID", '0001');
    } else {
      Storage.set("SCHOOL_ID", schoolIdValue);
    }
    Storage.set("SCHOOL_BUILDING_ID", schoolBuildingValue);
    Storage.set("PERIOD_ID", periodCode);
    Storage.set("LIST_PERIOD", periodList);
    Storage.set("SCHOOL_BUILDING_LIST", optSchoolBuilding);
    //* 6-1: systemCode 
    Storage.set('SYSTEM_CODE', systemCode);
    Storage.set('OLD_SYSTEM_CODE', systemCode);
    setIsConfirm(true);
    setLoading(true);
    dispatch(methodApiNoAuth.getListUserProfile({
      params: { systemCode, schoolCode : schoolIdValue, schoolBuildingCode : schoolBuildingValue, periodCode },
      success: res => {
        Storage.set("MAIN_MODE", isMode);
        Storage.set('OLD_MAIN_MODE', isMode);
        // Save list user to redux store
      },
      final: () => setLoading(false)
    }))
    // history.push({
    //   pathname: `/sign-in?schoolId=${schoolIdValue}`
    // });
    // return <Redirect to={`/?schoolId=${schoolIdValue}`} />;
    // onSubmit()
  }

  const handleBlur = (event, type, value) => {
    setChooseInput(0)
    const chooseCond = {
      1: value && schoolIdValue && schoolBuildingValue,
      2: systemCode && value && schoolBuildingValue,
      3: systemCode && schoolIdValue && value
    
    }
    const chooseValue = {
      1: { systemCode: value, schoolId: schoolIdValue, schoolBuildingId: schoolBuildingValue },
      2: { systemCode, schoolId: value, schoolBuildingId: schoolBuildingValue },
      3: { systemCode, schoolId: schoolIdValue, schoolBuildingId: value }
     
    }
    if(chooseCond[type]) {
      setPeriodList(null);
      Storage.remove('PERIOD_ID');
      Storage.remove('LIST_PERIOD');
      setLoading(true)
      dispatch(methodApiNoAuth.getListTimeTables({
        params: chooseValue[type],
        success: res => {
          setPeriodList(res?.data?.content)
          setPeriodCode(res?.data?.content[0]?.periodCode)
        },
        final: () => setLoading(false)
        // failed: err => console.log(err)
      }))
    }
  }
  const callGetListSchoolBuilding = useCallback((value = null, system = null) => {
    // setLoading(true)
    dispatch(setLoadingApp(true))
    Storage.remove('SCHOOL_BUILDING_ID');
    Storage.remove('SCHOOL_BUILDING_LIST');
    dispatch(
      getListSchoolBuilding({
        systemCode: system || systemCode,
        schoolId: value === 'reset_system' ? undefined : (value || schoolIdValue || undefined),
        success: (r) => {
          setOptSchoolBuilding(r?.content || [])
        },
        error: () => {},
        finally: () => {
        setTimeout(() => {
          dispatch(setLoadingApp(false))
        }, 100);
        }
      })
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schoolIdValue, systemCode])
  React.useEffect(() => {
    if (Storage.get('SCHOOL_ID') !== null) {
        setSchoolIdValue(Storage.get('SCHOOL_ID'));
    } else if (systemCode === '1') {
        setSchoolIdValue('0001');
      }
    if (Storage.get('OLD_MAIN_MODE') !== null) {
      setIsMode(Storage.get('OLD_MAIN_MODE'));
    }
    //* 6-1-22
    if (Storage.get('OLD_SYSTEM_CODE') !== null) {
      setSystemCode(Storage.get('OLD_SYSTEM_CODE'));
    }
    if (Storage.get('SCHOOL_BUILDING_ID') !== null) {
      setSchoolBuildingValue(Storage.get('SCHOOL_BUILDING_ID'));
    }
    if(Storage.get('SCHOOL_BUILDING_LIST') !== null){
      setOptSchoolBuilding(Storage.get('SCHOOL_BUILDING_LIST'));
    }
    // if (Storage.get('PERIOD_ID') !== '') {
    //   Storage.remove('PERIOD_ID');
    // }
    if(Storage.get('LIST_PERIOD') !== null){
      setPeriodList(Storage.get('LIST_PERIOD'));
    }
    if(Storage.get('PERIOD_ID') !== null){
      setPeriodCode(Storage.get('PERIOD_ID'));
    }
    if(Storage.get('SCHOOL_BUILDING_ID') === null && Storage.get('SCHOOL_BUILDING_LIST') === null) {
      callGetListSchoolBuilding();
    }
    if(Storage.get('SYSTEM_CODE') && Storage.get('SCHOOL_ID') !== null && Storage.get('SCHOOL_BUILDING_ID') !== null && Storage.get('PERIOD_ID') === null && Storage.get('LIST_PERIOD') === null) {
      setPeriodList(null);
      Storage.remove('PERIOD_ID');
      Storage.remove('LIST_PERIOD');
      setLoading(true)
      dispatch(methodApiNoAuth.getListTimeTables({
        params:  { systemCode: Storage.get('SYSTEM_CODE'), schoolId: Storage.get('SCHOOL_ID'), schoolBuildingId: Storage.get('SCHOOL_BUILDING_ID') },
        success: res => {
          setPeriodList(res?.data?.content)
          setPeriodCode(res?.data?.content[0]?.periodCode)
        },
        final: () => setLoading(false)
        // failed: err => console.log(err)
      }))
     
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [callApi, setCallApi] = useState(false)
  
  useEffect(() => {
    if(callApi){
      if(schoolIdValue && schoolBuildingValue){
        setCallApi(false)
        setPeriodList(null);
        Storage.remove('PERIOD_ID');
        Storage.remove('LIST_PERIOD');
        setLoading(true)
        dispatch(methodApiNoAuth.getListTimeTables({
          params:  { systemCode, schoolId: schoolIdValue, schoolBuildingId: schoolBuildingValue },
          success: res => {
            setPeriodList(res?.data?.content)
            setPeriodCode(res?.data?.content[0]?.periodCode)
          },
          final: () => setLoading(false)
          // failed: err => console.log(err)
        }))
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callApi, schoolIdValue, schoolBuildingValue, systemCode])

  const [watermark, setWatermark] = useState(false)
  const handleClickOutside = () => {
    setChooseInput(0)
    setWatermark(false)
    setCallApi(true)
  }
  const UIWatermark = useMemo(() => watermark ? <WatermarkSignIn 
   onClick={handleClickOutside}
  /> : null , [watermark])
  const handleClickinside = (type) => {
    setCallApi(false)
    const inputMap = {
      1: () => setChooseInput(1),
      2: () => setChooseInput(2)
    }
    setWatermark(true)
    // eslint-disable-next-line no-console
    const handler = inputMap[type] || (() => console.log('end'));
    handler();
  }
  const handleClickDifferentInput = () => {
    setWatermark(false)
    setChooseInput(0)
  }
  const handleClear = () => {
    setSchoolBuildingValue('');
    setPeriodList(null);
    // eslint-disable-next-line no-unused-expressions
    systemCode === '1' ? setSchoolIdValue('0001') : setSchoolIdValue('') 
  }

  const handleOnBlurSchoolId = (value) => {
    if (value && value !== schoolIdValue) {
      setSchoolIdValue(value);

      callGetListSchoolBuilding(value)
    }
    handleClickDifferentInput()
    // Storage.remove('SCHOOL_BUILDING_ID');
    // Storage.remove('PERIOD_ID');
    // Storage.remove('LIST_PERIOD');
    // Storage.remove('SCHOOL_BUILDING_LIST');
    setSchoolBuildingValue('');
    setPeriodList(null);
    setCallApi(true)
  }
  
  const InputSchoolBuildingId = <>
     
           {
            turnOffKeyboard ? (
              <input type="text" className="form-control" id="schoolBuildingId"  
              name="schoolBuildingId" 
              style={{position: "relative", zIndex: "1"}} 
              autoComplete="off"
              // inputMode='none'
              // ref={refSchoolBuilding}
              value={schoolBuildingValue}
              autoFocus={chooseInput === 2}
              onChange={e => {
                setSchoolBuildingValue(e.target.value);
              }}
              onClick={() => handleClickinside(2)}
              // onBlur={(event) => {
              //   setSchoolBuildingValue(event.target.value)
              //   handleBlur(event, 3, event.target.value);
              // }}
            
            />
            ) : (
              <InputKeyboard className={`form-control ${chooseInput === 2 ? 'active' : ''}`} onClick={() => handleClickinside(2)} 
              >
                {schoolBuildingValue}
              </InputKeyboard>
            )
           }
  </>
  const FormsSelectMode = () => (
    <>
      <FormGroup className="form-group">
        {/* <Switch checked={turnOffKeyboard} onChange={() => {
            setTurnOffKeyboard(!turnOffKeyboard)
         }} /> */}
        <label className="form-label"> システムコード </label>
        {/* <select
            id="systemCode"
            name="systemCode"
            
            onChange={(event) => {
              handleClickDifferentInput()
              setSystemCode(event.target.value);

              setSchoolBuildingValue('');
              setPeriodList(null);
              Storage.remove('SCHOOL_BUILDING_ID');
              Storage.remove('PERIOD_ID');
              Storage.remove('LIST_PERIOD');
              Storage.remove('SCHOOL_BUILDING_LIST');

              setWatermark(0)
              if (event.target.value !== '1') {
                setSchoolIdValue('');
              callGetListSchoolBuilding(null, 2)

                // handleBlur(event, 1, event.target.value)

              } else {
                setSchoolIdValue('0001');
                // handleBlur(event, 1, 1)
                callGetListSchoolBuilding(null, 1)

              }
            }} 
            // onMouseEnter={() => setWatermark(0)}
            value={systemCode}
            className="form-control" style={{WebkitAppearance:"auto", marginBottom:"5px", position: 'relative', zIndex: '1'}}>
            <option value="1">内部</option>
            <option value="2">外販</option>
        </select> */}
        <SelectSystem
          funcOnChange={(event) => {
            handleClickDifferentInput();
            setSystemCode(event.value);

            setSchoolBuildingValue("");
            setPeriodList(null);
            Storage.remove("SCHOOL_BUILDING_ID");
            Storage.remove("PERIOD_ID");
            Storage.remove("LIST_PERIOD");
            Storage.remove("SCHOOL_BUILDING_LIST");

            setWatermark(0);
            if (event.value !== "1") {
              setSchoolIdValue("");
              callGetListSchoolBuilding("reset_system", 2);

              // handleBlur(event, 1, event.target.value)
            } else {
              setSchoolIdValue("0001");
              // handleBlur(event, 1, 1)
              callGetListSchoolBuilding("0001", 1);
            }
          }}
          systemCode={systemCode}
        />
        {systemCode !== "1" ? (
          <>
            <label htmlFor="schoolId" className="form-label">
              {" "}
              塾コード{" "}
            </label>
            {turnOffKeyboard ? (
              <InputNumberCustom
                type="text"
                className="form-control"
                id="schoolId"
                name="schoolId"
                style={{ position: "relative", zIndex: "0" }}
                autoComplete="off"
                autoFocus={chooseInput === 1}
                value={schoolIdValue}
                onBlur={handleOnBlurSchoolId}
              />
            ) : (
              <InputKeyboard
                className={`form-control ${chooseInput === 1 ? "active" : ""}`}
                onClick={() => handleClickinside(1)}
              >
                {schoolIdValue}
              </InputKeyboard>
            )}
          </>
        ) : null}

        {/* <label htmlFor="userId" className="form-label">校舎コード</label>
          <div className="input-icon">
            <img src={InputIcon} alt='' />
            <Input
              {...register("userId")}
              invalid ={!!errors?.userId}
              autoFocus
              type="text"
              className="form-input"
              id="userId"
              name="userId"
              placeholder="例: 0001"
            />
          </div> */}
        <label className="form-label">校舎名</label>
        {/* {InputSchoolBuildingId} comment - 17/01/2023 */}
        <SelectSchoolBuilding
          data={optSchoolBuilding}
          schoolBuildingId={schoolBuildingValue}
          setSchoolBuildingId={setSchoolBuildingValue}
          callApi={() => setCallApi(true)}
        />
        <div
          style={{
            position: "absolute",
            left: "47px",
            width: "calc(100% - 94px)"
          }}
        >
          <SelectPeriod
            data={periodList}
            periodCode={periodCode}
            setPeriodCode={setPeriodCode}
          />
        </div>
        {/* <CustomSelect options={periodList} value={periodCode} onChange={setPeriodCode}/> */}
        <input
          type="text"
          className="form-control"
          disabled
          style={{
            height: "0px",
            paddingTop: "0px",
            paddingBottom: "0px",
            opacity: "0"
          }}
        />
      </FormGroup>

      <Button
        type="button"
        className="form-submit w-100 mt-3 btn_login-new"
        color="primary"
        disabled={
          state.isLoading ||
          !schoolIdValue ||
          !schoolBuildingValue ||
          !periodCode
        }
        onClick={handleSelectMode}
      >
        登録
      </Button>
      <SelectMode />
      {UIWatermark}
    </>
  );


  const handleRouting = () => {
    
    window.location = `${getEnv('SCORING_DOMAIN')}?schoolId=${schoolId}`
    // test();
  }

  const resetMainMode = (event) => {
    event.preventDefault();
    Storage.remove("MAIN_MODE");
    window.location.reload();
    return true;
  }

  const getMsgApi = () => {
    const url = `${getEnv('REACT_APP_API_SERVER')}/auth/system/configure/system-state`;
    const configParams = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      }
    };
    callApiNoToken(
        url,
        configParams,
        null,
        (response) => { 
          setMsgApiMaintain(response.data?.description !== null ? response.data?.description : 'システムのメンテナンスが実施中です。しばらくお待ちしてください。');
          if (response?.data?.systemState && response.data?.showDescription ) {
            setIsShowMsg(true);
          } else {
            setIsShowMsg(false);
          }
        },
        (error) => { setIsShowMsg(false) }
    )
  }

  React.useEffect(() => {
      if(!Storage.has("MAIN_MODE")){
        getMsgApi();
      }
  }, []);
  return(
    <HomePageLayout logout={resetMainMode} styleLayout={{
      centerScreen: true,
      flexColumn: !Storage.has("MAIN_MODE")
    }}>
       
    {(Storage.has("MAIN_MODE")) ? <IntroPage logout={resetMainMode}
     setLoading={setLoading}
     /> :   
    <>
     {isShowMsg && (
          <div className="toast" role="alert" aria-live="assertive" aria-atomic="true"
          style={{ display:"flex", alignItems:"center", color:"#ffffff", backgroundColor:"#c93434", opacity: "1", borderRadius:"10px",
          flexBasis: 'auto', margin: '0', overflowWrap: 'anywhere', maxWidth: '100%', marginTop: '-20px', marginBottom: '-20px'
          } }>
            <div className='toast__icon' style={{fontSize:"30px", paddingRight: "10px", paddingLeft: "10px"}}>
              <i className='fas fa-exclamation-circle' />
            </div>
            <div className="toast-body" style={{paddingLeft: "2px", paddingBottom:"2px"}}>
              <h4 className='toast__title' style={{fontSize:"16px", fontWeight:"600"}}>通知</h4>
              <p className='toast__msg' style={{fontSize:"14px"}}>{msgApiMaintain}</p>
            </div>
          </div>
        )}
    <Wrapper className="signup">

      

{/* <img src={Logo} alt="logo" /> */}

<FormContent style={{height:"auto", marginTop: 0, position: 'relative'}}>
{Storage.has("MAIN_MODE") ?
  <i aria-hidden="true" className="fas fa-cog" 
  onClick={resetMainMode}
  style={{float:"right", marginTop:"-20px", marginRight:"-30px", fontSize:"22px", cursor:"pointer"}} />
 : ''}
  <p><span style={{
   color: '#E37713' 
  }}><b>AI 学トレ</b></span></p>
  <h2 className='mb-3'>
     {Storage.has("MAIN_MODE") ? 'ログイン' : '設定' }
  </h2>
  <FormsSelectMode/> 
</FormContent>

{/* <KeyboardCaculatorNoFunc handleClear={handleClear} chooseInput={chooseInput} setSchoolIdValue={setSchoolIdValue}
setSchoolBuildingValue={setSchoolBuildingValue}/> */}

</Wrapper>
    </>
  } 
  {/* {loading ? <LoadingNew /> : ''} */}
    </HomePageLayout>
  
  )
};

export default SignIn;
