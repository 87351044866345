import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import Storage from "../../../utils/storega";
import { signIn } from "../../../store/actions/auth";

const Wrapper = styled.div`
display: flex;
justify-content: center;
align-items: center;
height: 100vh;
width: 100vw;
`
const DetailUser = styled.div`
background-color: #fff;
padding: 20px;
border-radius: 5px;
margin-bottom: 20px;
`
const ButtonLogin = styled.button`
padding: 10px 20px;
border-radius: 5px;
border: none;
background-color: #fff;

`
const ErrorMess = styled.div`
color: red;
`
export const LoginAdmin = () => {
const dispatch = useDispatch();
// username and schooldId get from URL 
const getUrl = window.location.href;
const url = new URL(getUrl);
const username = url.searchParams.get("user");
const schoolId = url.searchParams.get("school");
const systemCode = url.searchParams.get("system");
const validateId = Boolean(username && schoolId);
const validateSystemCode = Boolean(systemCode === "1" || systemCode === "2");
// URL: host/sign-in-admin?user=27&school=0001&system=1
const handleSignIn = () => {
    dispatch(
        signIn({
          passWord: "",
          username,
          schoolId,
          systemCode,
          handleSuccess: () => {
            Storage.set("SCHOOL_ID", schoolId);
            Storage.set('SYSTEM_CODE', systemCode);
            Storage.set('OLD_SYSTEM_CODE', systemCode);
            Storage.set("MAIN_MODE", 'input');
            Storage.set('OLD_MAIN_MODE', 'input');
            Storage.set("USER_NAME", 'ADMIN');
            Storage.set("ADMIN_LOGIN", true);
            // if(Storage.get("SELECT_MODE") === 'select'){
            //   Storage.set("MODE_SELECTED", 'LIST')
            //   Storage.set("TYPE_SELECT_MODE", 'LIST_LESSON')
            // } else {
            //     Storage.set("TYPE_SELECT_MODE", 'NORMAL')
            // }
          }
        }, () => {
            // window.location.href = "/sign-in";
        })
      );
}
useEffect(() => {
   if(!validateId || !validateSystemCode){
    window.location.href = "/sign-in";
   }
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [])
    return (
        <Wrapper>
      <div>
      <DetailUser>
            Confirm your information
           <div>User: {username}</div>
           <div>School: {schoolId}</div>
           <div>System: {systemCode}</div>
           </DetailUser>
            <ButtonLogin type="button" onClick={handleSignIn}>
                Sign In
            </ButtonLogin>  
      </div>
        </Wrapper>
    )
}