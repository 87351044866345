import React from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

const SelectContainer = styled.div`
  position: relative;
  width: 200px;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow: hidden;
`;

const SelectLabel = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 20px;
  font-size: 16px;
  color: #333;
  cursor: pointer;
  user-select: none;
`;

const SelectOptions = styled.div`
  position: absolute;
  top: 40px;
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: scroll;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  z-index: 1;
`;

const SelectOption = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  font-size: 16px;
  color: #333;
  cursor: pointer;
  user-select: none;
  &:hover {
    background-color: #eee;
  }
`;

const CustomSelect = ({ options, value, onChange }) => {
  const [open, setOpen] = React.useState(false);
  const toggleOpen = () => setOpen(!open);
  const optionsRef = React.useRef(null);
  const handleClickOutside = event => {
    if (optionsRef.current && !optionsRef.current.contains(event.target)) {
      setOpen(false);
    }
  };
  React.useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  });

  return createPortal(
    <SelectContainer>
      <SelectLabel onClick={toggleOpen}>
        {value || 'Select an option...'}
      </SelectLabel>
      {open && (
        <SelectOptions ref={optionsRef}>
          {options.map(option => (
            <SelectOption
              key={option.value}
              onClick={() => {
                onChange(option.value);
                toggleOpen();
              }}
            >
              {option.label}
            </SelectOption>
          ))}
        </SelectOptions>
      )}
    </SelectContainer>,
    document.body
  );
};

export {
    CustomSelect
}