import { TYPES } from '../actions';

const initialState = {
  status: null,
  error: null,
  listSchool: [],
  listRanking: [],
  rankingByUser: {},
  isLoading: false,
  pageNumber: 0,
  pageSize: 0,
  totalElements: 0,
  totalPages: 1
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_SCHOOL_LEVEL_BY_SUBJECT_REQUEST:
    case TYPES.GET_RANKING_LIST_REQUEST:
      case TYPES.GET_RANKING_BY_USER_REQUEST:
      return {
        ...state,
        status: action.type,
        isLoading: true
      };

    case TYPES.GET_SCHOOL_LEVEL_BY_SUBJECT_SUCCESS:
      return {
        ...state,
        status: action.type,
        listSchool: action.data,
        isLoading: false
      };

    case TYPES.GET_RANKING_LIST_SUCCESS:
      return {
        ...state,
        status: action.type,
        listRanking: action.data.listRanking.content,
        pageNumber: action.data.listRanking?.pageNumber,
        pageSize: action.data.listRanking?.pageSize,
        totalElements: action.data.listRanking?.totalElements,
        totalPages: action.data.listRanking?.totalPages,
        isLoading: false
      };

    case TYPES.GET_RANKING_BY_USER_SUCCESS:
      return {
        ...state,
        status: action.type,
        rankingByUser: action.data.rankingByUser,
        isLoading: false
      }

    case TYPES.GET_SCHOOL_LEVEL_BY_SUBJECT_FAILED:
    case TYPES.GET_RANKING_LIST_FAILED:
    case TYPES.GET_RANKING_BY_USER_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.err,
        isLoading: false
      };

    default:
      return state;
  }
};
