/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import backout from "../assets/backout.png";

export const BackButton = ({ onClick }) => (
        (
            <div>
            <img src={backout} alt="" style={{
                cursor: 'pointer',
                position: 'fixed',
                top: '40px',
                left: '40px',
                zIndex: '999'
            }} onClick={onClick}/>
        </div>
        )
    )