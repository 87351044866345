import styled from "styled-components";
import ImageContent from "../assets/images/exma.png";

export const Container = styled.div`
  width: 100%;
  padding: 2.38rem 0;
  background-color: ${({ theme }) => theme.darkPrimaryColor};

  // background-image: url(${ImageContent});
  // background-repeat: no-repeat;
  // background-position: center;
  // background-size: cover;
  // min-width: 100%;
  // min-height: 100%;
  
  & .content__background {
    position: fixed; 
    top: 0; 
    left: 0; 
    
    /* Preserve aspet ratio */
    min-width: 100%;
    min-height: 100%;
  }

  & .container {
    position: relative;
    z-index: 20;
  }
`;