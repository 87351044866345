import styled from "styled-components";
import ButtonUI from "../button/CustomButton";

export const InfoElement = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ newWidth }) => newWidth || '160'}px;
  height: 38px;

  border-radius: 6px;
  padding: 0.5rem .5rem;
  background: ${({ theme }) => theme.lightBg};
  color: ${({ theme }) => theme.borderColor};
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  z-index: 10;
  
  & .dot__info {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: .5rem;
    background: ${({ theme }) => theme.borderColor};
  }
`;

export const HomeBtn = styled(ButtonUI)`
  background-color: transparent;
  background: ${({ theme }) => theme.gradientBorder};
  color: ${({ theme }) => theme.textDefault};
  min-width: ${({ newwidth }) => newwidth || '90'}px;
  border: 1px solid transparent;

  &:hover {
    border: 1px solid ${({ theme }) => theme.textDefault} !important;
  }
`

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & .group__small {
    flex: 2;
  }

  & .group__large {
    flex: 3;
    justify-content: space-between;
  }

  & .group__normal {
    flex: 2;
    justify-content: flex-end;
  }
`

export const BackBtn = styled(ButtonUI)`
  transition: none;
  width: 194px;
  ${({ responsiveToMediumScreen }) =>
    responsiveToMediumScreen &&
    `
    @media screen and (max-width: 991px) {
     width: 120px;
    }
 `}
`