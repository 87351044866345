const theme = {
  // COLOR
  mainColor: 'rgba(56, 215, 208, 0.25)',
  mainColorHover: 'rgba(56, 215, 208, 0.45)',
  primaryColor: '#195DF2',
  secondaryColor: 'linear-gradient(90deg, rgba(255, 193, 74, 0.57) 0%, rgba(255, 167, 0, 0.42) 91.15%)',
  darkPrimaryColor: '#0C4286',
  darkBg: 'linear-gradient(90deg, rgba(11, 36, 60, 0.95) 0%, rgba(6, 36, 100, 0.9) 100%)',
  lightBg: 'linear-gradient(90deg, rgba(56, 215, 208, 0.45) 0%, rgba(56, 215, 208, 0.36) 0.01%, rgba(67, 221, 214, 0.09) 91.15%)',
  borderColor: '#38D7D0',
  gradientBorder: 'radial-gradient(50% 50% at 50% 50%, #38D7D0 0%, #00B7AF 100%)',
  textDefault: '#fff',
  textDarkBlue: '#292B60',
  textGrey: '#5D5F82',
  textLight: '#ACDCFF',
  thirdColor: '#FFC24E',
  bgRoadMap: 'linear-gradient(90deg, rgba(56, 215, 208, 0.2) 0%, rgba(56, 215, 208, 0.16) 0.01%, rgba(56, 215, 208, 0.03) 91.15%)',
  bgModal: 'linear-gradient(180deg, #05093C 0%, #1B2353 100%)',
  black: '#000',
  borderLight: '#A4A5A6',
  bgLight: '#d6d6d6f5',

  buttonColor: {
    NOT_LEARN: '#EB5757 ',
    LEARNING: '#0067EA',
    COMPLETED: '#747597',
    SUCCESS: '#04D88B',
    WARNING: '#FF8A00',
    BLUE_LIGHT: '#00AECB'
  }

  // WIDTH, HEIGHT    

  // FONT SIZE

  // ANIMATED

}

export default theme;