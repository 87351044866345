import React from "react";
import styled, { keyframes } from "styled-components";
import loadingImg from "../assets/loading.png";

const Loading = styled.div`
/* fullscreen and center*/
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const animation = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`
const LoadingImg = styled.img`
    animation: ${animation} 1s linear infinite;
`
export const LoadingNew = () => (
        <Loading>
          <LoadingImg src={loadingImg} />
        </Loading>
    )