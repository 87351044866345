import { stringify } from "query-string";
import {
  getEnv
} from "../../configs/env";
import { SchoolId } from "../../constant/commonData";
import {
  callApi,
  callApiNoToken,
  logError
} from "../../utils/request";
import Storage from "../../utils/storega";
import {
  TYPES
} from "./index";
import { setLoadingApp } from "./common";

const updateStatus = (status, statusInfo) => ({
  type: status,
  data: statusInfo
});

const signIn = (payload, errorHandle = () => { }) => {

  const config = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json"
    },
    body: JSON.stringify({
      schoolId: payload.schoolId,
      username: payload.username,
      passWord: "",
      // systemCode: Storage.get('SYSTEM_CODE') === null ? '1' : Storage.get('SYSTEM_CODE')
      systemCode: payload.systemCode,
      timetableId: payload.timetableId
    })
  };
  return (dispatch) => {
    dispatch(updateStatus(TYPES.SIGN_IN_REQUEST));
    dispatch(updateStatus('LOADING_ON'))
    callApiNoToken(
      `${getEnv("REACT_APP_API_SERVER")}/auth/login`,
      config,
      null,
      async (data) => {
        if (payload?.handleSuccess) {
          payload?.handleSuccess();
        }
        await dispatch(updateStatus(TYPES.SIGN_IN_SUCCESS, {
          user: data.data,
          examId: payload.examId
        }));
        await dispatch(updateStatus('UPDATE_LIST_USER_SUCCESS', {
          id: payload.username,
          data: {
            isCheckedSanaru: data.data.isCheckedSanaru
          }
        }))
      },
      (err) => {
        errorHandle()
        dispatch(updateStatus('LOADING_OFF'))
        dispatch(updateStatus(TYPES.SIGN_IN_FAILED, {
          error: err
        }));
      }
    );
  };
};

const newGetCurrentExam = ({
  onSuccess = () => { },
  onError = () => { }
}) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/auth/exam/current-exam?isCurrentUser=${true}`;

  const configParams = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`
    }
  };

  return (dispatch) => {
    dispatch(updateStatus('LOADING_ON'))
    dispatch(updateStatus(TYPES.GET_CURRENT_EXAM_BY_USER_REQUEST))
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.GET_CURRENT_EXAM_BY_USER_SUCCESS, {
            currentExamByUser: response.data
          })
        );
        onSuccess(response.data)
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.GET_CURRENT_EXAM_BY_USER_FAILED, { err: error.errors })
        );
        dispatch(updateStatus('LOADING_OFF'))
        onError()
      },
      () => { },
      {
        isHaveLogParams: true
      }
      // () => dispatch(updateStatus('LOADING_OFF'))
    )
  };
}

const getListLessonCurrentOfUserWithToken = ({
  onSuccess = () => { },
  onError = () => { },
  params = {
    size: 10,
    page: 0
  }
}) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/auth/schedule/currents?${stringify(params)}`;

  const configParams = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`
    }
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_LIST_CURRENT_LESSON_USER_REQUEST))
    dispatch(updateStatus('LOADING_ON'))
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        dispatch(updateStatus(TYPES.GET_LIST_CURRENT_LESSON_USER_SUCCESS, {
          list: response.data
        }))
        onSuccess(response.data)
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_LIST_CURRENT_LESSON_USER_FAILED, {
          err: error.errors
        }))
        onError()
      },
      () => dispatch(updateStatus('LOADING_OFF')),
      {
        isHaveLogParams: false
      },

    )
  };
}

const getCurrentExam = ({
  onSuccess = () => { },
  onError = () => { }
}) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/auth/moshi-exam/current-exam`;
  const configParams = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`
    }
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_CURRENT_EXAM_REQUEST))
    dispatch(updateStatus('LOADING_ON'))
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        dispatch(updateStatus(TYPES.GET_CURRENT_EXAM_SUCCESS, {
          data: response.data
        }))
        onSuccess(response.data)
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_CURRENT_EXAM_FAILED, {
          err: error.errors
        }))
        onError()
      },
      () => dispatch(updateStatus('LOADING_OFF')),
      {
        isHaveLogParams: false
      },

    )
  };
}

const getListMoshiToFilter = ({
  onSuccess = () => { },
  onError = () => { },
  params = { }
}) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/auth/moshi-exam-result-import?${stringify(params)}`;
  const configParams = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`
    }
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_LIST_MOSHI_TO_FILTER_REQUEST))
    dispatch(updateStatus('LOADING_ON'))
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        dispatch(updateStatus(TYPES.GET_LIST_MOSHI_TO_FILTER_SUCCESS, {
          data: response.data?.content
        }))
        onSuccess(response.data?.content)
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_LIST_MOSHI_TO_FILTER_FAILED, {
          err: error.errors
        }))
        onError()
      },
      () => dispatch(updateStatus('LOADING_OFF')),
      {
        isHaveLogParams: false
      },

    )
  };
}

const deleteExamListSelected = ({
  body = { }
}) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/auth/moshi-exam/result-import/exclude`;
  const configParams = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`
    },
    body: JSON.stringify(body)
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.DELETE_LIST_EXAM_SELECTED_REQUEST))
    dispatch(updateStatus('LOADING_ON'))
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        dispatch(updateStatus(TYPES.DELETE_LIST_EXAM_SELECTED_SUCCESS, {
          data: response
        }))
      },
      (error) => {
        dispatch(updateStatus(TYPES.DELETE_LIST_EXAM_SELECTED_FAILED, {
          err: error.errors
        }))
      },
      () => dispatch(updateStatus('LOADING_OFF')),
      {
        isHaveLogParams: false
      },
    )
  };
}

const getSmallQuestion = ({
  onSuccess = () => { },
  onError = () => { },
  params
}) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/auth/moshi-exam/small-question?${stringify(params)}`;
  const configParams = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`
    }
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_MOSHI_SMALL_EXAM_REQUEST))
    dispatch(updateStatus('LOADING_ON'))
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        dispatch(updateStatus(TYPES.GET_MOSHI_SMALL_EXAM_SUCCESS, {
          data: response.data
        }))
        onSuccess(response.data)
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_MOSHI_SMALL_EXAM_FAILED, {
          err: error.errors
        }))
        onError()
      },
      () => dispatch(updateStatus('LOADING_OFF')),
      {
        isHaveLogParams: false
      },

    )
  };
}

const createFirstExam = ({
  body
}) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/auth/moshi-exam/create-first-exam`;
  const config = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${Storage.get("USER_ACCESS_TOKEN")}`
    },
    body: JSON.stringify(body)
  };
  return (dispatch) => {
    callApi(
      url,
      config,
      null,
      async (data) => {
        await dispatch(updateStatus(TYPES.CREATE_FIRST_EXAM_SUCCESS, {
          data: data.data
        }))
      },
      (err) => { }
    );
  }
}

const getListMoshiExamResultImport = ({
  onSuccess = () => { },
  onError = () => { },
  params = {
    size: 10,
    page: 0
  }
}) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/auth/moshi-exam-result-import/question?${stringify(params)}`;
  const configParams = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`
    }
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_LIST_MOSHI_EXAM_RESULT_IMPORT_REQUEST))
    dispatch(updateStatus('LOADING_ON'))
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        dispatch(updateStatus(TYPES.GET_LIST_MOSHI_EXAM_RESULT_IMPORT_SUCCESS, {
          list: response.data
        }))
        onSuccess(response.data)
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_LIST_MOSHI_EXAM_RESULT_IMPORT_FAILED, {
          err: error.errors
        }))
        onError()
      },
      () => dispatch(updateStatus('LOADING_OFF')),
      {
        isHaveLogParams: false
      },
    )
  };
}

const generateExam = ({
  body
}) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/auth/moshi-exam/generate-exam`;
  const config = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${Storage.get("USER_ACCESS_TOKEN")}`
    },
    body: JSON.stringify(body)
  };
  return (dispatch) => {
    callApi(
      url,
      config,
      null,
      async (data) => {
        await dispatch(updateStatus(TYPES.GENERATE_EXAM_SUCCESS, {
          data: data.data
        }))
      },
      (err) => {
        dispatch(setLoadingApp(false))
      }
    );
  }
}

const moshiExamScoring = ({
  body
}) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/auth/moshi-exam/scoring`;
  const config = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${Storage.get("USER_ACCESS_TOKEN")}`
    },
    body: JSON.stringify(body)
  };
  return (dispatch) => {
    callApi(
      url,
      config,
      null,
      async (data) => {
        await dispatch(updateStatus(TYPES.MOSHI_SCORING_SUCCESS, {
          data: data.data
        }))
      },
      async (err) => { 
        dispatch(setLoadingApp(false));
        await dispatch(updateStatus(TYPES.MOSHI_SCORING_FAILED, {
          data: {}
        }))
      }
    );
  }
}

const moshiExamReset = (id) => {
  const url = `${getEnv("REACT_APP_API_SERVER")}/auth/moshi-exam/${id}`;
  const config = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${Storage.get("USER_ACCESS_TOKEN")}`
    }
  };
  return (dispatch) => {
    dispatch(updateStatus(TYPES.MOSHI_RESET_REQUEST, { data: {} }));
    callApi(
      url,
      config,
      null,
      async (data) => {
        await dispatch(
          updateStatus(TYPES.MOSHI_RESET_SUCCESS, { data })
        );
      },
      async (err) => {
        if (err?.errors?.message !== "EXAM_NOT_FOUND") {
          logError(err);
        }
        await dispatch(
          updateStatus(TYPES.MOSHI_RESET_FAILED, { data: err?.errors })
        );
      },
      null,
      {
        pushLog: false
      }
    );
  };
}

const updateStatusGenExam = ({
  examId, status, isNormalExam, cbEvent = () => { }
}) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/auth/moshi-exam/generate-exam-status`;
  const config = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${Storage.get("USER_ACCESS_TOKEN")}`
    },
    body: JSON.stringify({
      examId,
      status,
      isNormalExam
    })
  }
  return (dispatch) => {
    callApi(
      url,
      config,
      null,
      async (data) => {
        await dispatch(updateStatus(TYPES.UPDATE_STATUS_EXAM_SUCCESS, {
          data: data.data
        }))
      },
      (err) => { },
      cbEvent,
      {
        isHaveLogParams: true,
        isFirstParams: true
      }
    );
  }
}

const updateStatusMode = ({ params }) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/auth/study-mode?studyMode=${params}`;
  const config = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${Storage.get("USER_ACCESS_TOKEN")}`
    }
  }
  return (dispatch) => {
    callApi(
      url,
      config,
      null,
      async (data) => {
        await dispatch(updateStatus(TYPES.UPDATE_STUDY_MODE_SUCCESS, {
          data: data.data
        }))
      },
      (err) => { },
    );
  }
}

const getProfile = () => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/auth/profile`;

  const config = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${Storage.get("USER_ACCESS_TOKEN")}`
    }
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_PROFILE_REQUEST));
    callApi(
      url,
      config,
      null,
      (data) => {
        dispatch(updateStatus(TYPES.GET_PROFILE_SUCCESS, {
          profile: data.data
        }));
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_PROFILE_FAILED, {
          err: error.errors
        }));
      }
    );
  };
}

const logout = (payload) => ({
  type: "LOGOUT",
  payload
});

const clearAuth = () => (dispatch) => {
  dispatch(updateStatus(TYPES.CLEAR_DATA_AUTH_SUCCESS));
};

const clearMoshiScore = (payload) => ({
  type: TYPES.CLEAR_MOSHI_SCORE_SUCCESS,
  payload
})

const clearMoshiReset = (payload) => ({
  type: TYPES.MOSHI_RESET_REQUEST,
  payload
})

const clearDelExamSelected = (payload) => ({
  type: TYPES.DELETE_LIST_EXAM_SELECTED_REQUEST,
  payload
})

export {
  signIn,
  logout,
  clearAuth,
  getProfile,
  newGetCurrentExam,
  getListLessonCurrentOfUserWithToken,
  getCurrentExam,
  getListMoshiExamResultImport,
  generateExam,
  createFirstExam,
  updateStatusGenExam,
  getSmallQuestion,
  moshiExamScoring,
  moshiExamReset,
  updateStatusMode,
  clearMoshiScore,
  clearMoshiReset,
  getListMoshiToFilter,
  deleteExamListSelected,
  clearDelExamSelected
};
